/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, inject, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Field } from 'src/app/shared/field/Field';
import { AppFormControl, ControlOn } from '../app-form-control';
import { FormControlInterface } from '../form-control-interface';
import { FormErrorComponent } from '../form-error/form-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { CommsTemplate, CommsTemplateType } from 'src/app/model/CommsTemplate';
import { CommsTemplateService } from 'src/app/modules/social/comms-template-page/comms-templates.service';
import { MyInjector } from 'src/app/app.module';
import { MatDialog } from '@angular/material/dialog';
import { CommsTemplateUpdaterComponent } from 'src/app/modules/social/comms-template-page/comms-template-updater/comms-template-updater.component';
import { ConfirmDialogService } from '../../dialogs/confirmDialog';

export class RichTextField extends Field {
    /* Never default values in Field extensions, the default value gets applied AFTER constructor initialization,
       overriding all other parameters provided....
    type: FieldType = "richText";
    richTextHeight: string = "300";
    */
    richTextHeight: string;
    richTextCommsTemplateType: CommsTemplateType;
    availReplacements: string[];

    formatValue(value) {
        const div = document.createElement('div');
        div.innerHTML = value;
        const ret = this.getTextContent(div).substring(0, 252);
        return ret + (ret.length === 252 ? '...' : '')
    }
    setCommsTemplate(t: CommsTemplateType) {
        this.richTextCommsTemplateType = t;
        return this;
    }

    private getTextContent(n: Node) {
        let ret = '';
        n.childNodes.forEach((c: Node) => {
            if (c.nodeType === Node.ELEMENT_NODE) {
                if (c.childNodes.length <= 1) {
                    ret += c.textContent + ' ';
                } else {
                    ret += this.getTextContent(c);
                }
            } else if (c.nodeType === Node.TEXT_NODE) {
                ret += c.textContent + ' ';
            }
        });
        return ret;
    }
}



@Component({
    selector: 'app-form-rich-text',
    templateUrl: './form-rich-text.component.html',
    styleUrls: ['./form-rich-text.component.scss'],
    standalone: true,
    imports: [EditorComponent, MatInputModule, MatTooltipModule, FormsModule, ReactiveFormsModule,
        MatFormFieldModule, FormErrorComponent]
})
export class FormRichTextComponent implements FormControlInterface, OnInit {

    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    commsTmplSvc = inject(CommsTemplateService);
    richTextField: RichTextField;
    commsTemplates : CommsTemplate[] = [];
    currentTemplate : CommsTemplate;

    selfy = this;

    editorOptions = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        height: "300",
        menubar: false,
        relative_urls: false,
        remove_script_host: false,
        branding: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        //content_css: 'dark',
        //skin: 'oxide-dark', // For Dark themes?
        //theme: "inlite",
        plugins: [
            'advlist autolink lists link charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime table paste help'
        ],
        //     toolbar:
        //  'formatselect | bold italic superscript subscript | \
        //  table | outdent indent | \
        //  bullist numlist | removeformat | link image | undo redo help',
        toolbar: 'formatselect | chooseTemplate insertFldButton | bold italic link | outdent indent alignment bullist numlist | table | fullscreen | code help ',
        //content_css: './tinyMCE.css',
        //body_class: 'ourTinyMCE',
        setup: (ed) => {
            ed.on('init', () => {

                this.control.markAsPristine();
                ed.getBody().style.backgroundColor = 'rgba(243, 242, 241, 1)';
            });
            /* example, adding a group toolbar button */
            ed.ui.registry.addGroupToolbarButton('alignment', {
                icon: 'align-left',
                tooltip: 'Alignment',
                items: 'alignleft aligncenter alignjustify alignright'
            });
            if (this.richTextField.richTextCommsTemplateType) {
                ed.ui.registry.addMenuButton('chooseTemplate', {
                    text: 'Template',
                    fetch: this.setupTemplateMenu.bind(this, ed),
                });
            }
            if (this.richTextField.richTextCommsTemplateType?.replacements?.length > 0 || this.richTextField.availReplacements) {
                ed.ui.registry.addMenuButton('insertFldButton', {
                    text: 'Fields',
                    fetch: this.setupFieldMenu.bind(this, ed),
                });
            }
        }
    }

    getReplacements() {
        if (this.richTextField.availReplacements) {
            return this.richTextField.availReplacements;
        } else {
            return this.richTextField.richTextCommsTemplateType.replacements
        }
    }

    setupFieldMenu(ed, callback) {
        const items = [];
        const rs = this.getReplacements();
        if (rs) {
            rs.forEach( r => items.push({
                type: 'menuitem',
                text: r,
                onAction: function () {
                    ed.insertContent(`[${r}]`);
                }
            }));
        }
        callback(items);
    }

    setEditorContent(ed, t: CommsTemplate) {
        this.currentTemplate = t;
        ed.setContent(t.content);
        this.richTextField.control.setValue(t.content, {emitEvent: false});
        this.richTextField.control.markAsDirty();
        this.richTextField.control.markAsTouched();
    }

    setupTemplateMenu(ed, callback) {
        const items = [];
        this.commsTmplSvc.get(true).subscribe( (ts: CommsTemplate[]) => {
            ts.forEach ( t => {
                if (t.type === this.richTextField.richTextCommsTemplateType.id) {
                    items.push({
                        type: 'menuitem',
                        text: (this.currentTemplate?.id === t.id ?  '* ' : '') + t.title,
                        onAction: this.setEditorContent.bind(this, ed, t),
                        disabled: this.currentTemplate?.id === t.id
                    })
                }
            });
            const ct = ed.getContent();
            if ( ct && ct !== this.currentTemplate?.content) {
                items.push({type: 'separator'}),
                items.push({
                        type: 'menuitem',
                        text: 'Save Template',
                        onAction: this.saveTemplate.bind(this, ed)
                });
            }

            callback(items);
        });
    }

    saveTemplate(ed) {
        const dialog = MyInjector.instance.get(MatDialog);
        dialog.open(CommsTemplateUpdaterComponent, {data: {
            type: this.richTextField.richTextCommsTemplateType,
            template: this.currentTemplate,
            content: ed.getContent()
        }}).afterClosed().subscribe( tmpl => {
            console.log(tmpl);
            if (tmpl.id !== this.currentTemplate.id) {
                this.commsTemplates.push(tmpl);
                this.currentTemplate = tmpl;
            }
        });
    }

    ngOnInit(): void {

        this.editorOptions.height = this.richTextField.richTextHeight;

    }
    public static make(heading: string, value: string, options: Partial<RichTextField> = {}) {
        const field: RichTextField = new RichTextField({ type: 'richText', label: heading, cellOpts: { heading }, value, ...options });
        field.richTextHeight = "300";
        field.initFromPartial(options);
        field.formControlFactory = FormRichTextComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormRichTextComponent);
        const fld = ctl.field as RichTextField;
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
        componentRef.instance.richTextField = fld;

        if (fld.richTextCommsTemplateType) {
            const commsTmplSvc = MyInjector.instance.get(CommsTemplateService);
            commsTmplSvc.get(true).subscribe()
        }
    }

    showFullContent() {
        const cds = MyInjector.instance.get(ConfirmDialogService);
        cds.alert(this.control.field.label, this.control.value, null, true);
    }
}
