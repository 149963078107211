/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, convertToParamMap, ParamMap, Params, Router } from '@angular/router';
import { AbstractObject } from 'src/app/model/abstract-object';
import { TableView } from 'src/app/model/sys/nav-item';
import { FormPageComponent } from '../../form/form-page/form-page.component';
import { FormMode } from '../../form/form.component';
import { FormConfig } from "../../form/FormConfig";
import { HistoryComponent } from '../../form/history/history.component';
import { IsNarrowService } from '../../is-narrow.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { Field } from '../../field/Field';
import { PicklistField } from '../../field/PicklistField';
import { ButtonField } from '../../field/ButtonField';
import { DateTimeField } from '../../form/form-date-time/form-date-time.component';

@Component({
    selector: 'app-table-page-header',
    templateUrl: './table-page-header.component.html',
    styleUrls: ['./table-page-header.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatIconModule, FormsModule, MatTooltipModule]
})
export class TablePageHeaderComponent implements OnInit {

    @Input() mode: FormMode;
    @Input() id: string;
    @Input() data: AbstractObject[];
    @Input() config: FormConfig;
    @Input() formFocus: AbstractObject;
    @Input() form: FormPageComponent;
    @Output() tableSettings = new EventEmitter();
    @Output() toggleCharts = new EventEmitter();
    @Output() printTable = new EventEmitter();

    showCharts = true;

    isPhone = false;

    customFilterText = '-- custom filter -- ';
    filterCustomised = true;
    customFilter: TableView = { id: null, name: '-- Custom Filter --', filterFields: {} }
    selectedFilter: TableView = this.customFilter;

    constructor(private matDialog: MatDialog, private router: Router, private activeRoute: ActivatedRoute, isNarrowSvc: IsNarrowService) {
        isNarrowSvc.detectVeryNarrow().subscribe(result => { this.isPhone = result; });
    }

    goBack() {
        history.back();
        /*
        * How to know if the list view had _forceTeam on it?
        * Or was using a view?
        * Or had custom parameters
        * Without basically recreating the browser back button...

        const nr = this.config.navRoute;
        console.warn(this.config);
        if (this.configHasViews()) {
            console.warn('how', this.config.navRoute);
        } else {
            this.router.navigate([nr.url])
        }
        */
    }

    ngOnInit() {
        this.activeRoute.params.subscribe(params => this.checkFilter(params));
    }

    showHistory(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { config: this.config };
        dialogConfig.minWidth = "80%"
        this.matDialog.open(HistoryComponent, dialogConfig);
    }

    toggleTheCharts() {
        console.log(this.showCharts);
        this.toggleCharts.emit(this.showCharts);
    }

    myTableSettings() {
        this.tableSettings.emit();
    }

    downloadJSON() {
        if (this.config.mode === 'list') {
            this.download(JSON.stringify(this.data), 'json');
        } else if (this.formFocus) {
            this.download(JSON.stringify(this.formFocus), 'json');
        }
    }

    print() {
        this.printTable.emit();
    }

    configHasViews() {
        //config.navItem?.views && config.navItem.views.length > 0
        const userViews = this.config.navRoute.userViews
        if (userViews && userViews.length > 0) {
            return true;
        }
        return false;
    }

    applyPresetFilter($event) {
        // Selected Filter is Bound to the Select List
        const filter = this.selectedFilter; //$event.target.value; //this.config.navItem.filters.find( f => f.name === $event.target.value);
        if (!filter) {
            console.error('Filter could not be found for ' + $event.target.value, $event);
        }

        for (const field of this.config.fieldSet.fields) {
            field.filter.active = false;
        }
        //console.log({p:this.config.path, fields: filter.filterFields, filter});
        this.filterCustomised = false;
        //this.router.navigate(['/' + this.config.path, filter.filterFields]);
        this.router.navigate(['/' + this.config.navRoute.url, { _view: filter.id }]);
    }

    checkFilter(parms: Params) {
        const paramMap: ParamMap = convertToParamMap(parms);
        if (paramMap.has('_view')) {
            //const f = this.config.navItem.views.find( o => o.id === paramMap.get('_view'));
            const f = this.config.navRoute.userViews.find(o => o.id === paramMap.get('_view'));
            if (f) {
                this.selectedFilter = f;
            } else {
                console.error('Invalid view name ', paramMap, this.config);
            }
        } else {
            if (paramMap.keys.length !== 0) {
                this.selectedFilter = this.customFilter;
            }
        }
        /* Check all parameters to see if they match a known filter
        this.selectedFilter = this.customFilter;
        if (this.config?.navItem?.filters) {
            for (const f of this.config.navItem.filters) {
                //console.log({f, fKeys: Object.keys(f.filterFields).length, parms, pKeys: Object.keys(parms).length});
                if (Object.keys(f.filterFields).length === Object.keys(parms).length) {
                    const paramMap: ParamMap = convertToParamMap(parms);
                    const filterMap: ParamMap = convertToParamMap(f.filterFields);
                    let matches = 0;
                    for (const k of paramMap.keys) {
                        if (filterMap.has(k) && filterMap.get(k) == paramMap.get(k)) {
                            matches++
                        }
                    }
                    if (matches === Object.keys(parms).length) {
                        console.log('Matched ' + f.name);
                        this.selectedFilter = f;
                        break;
                    }
                }
            }
        }
        */
    }

    flattenObject(obj) {
        const flattenKeys = {};
        for (const i in obj) {
            if (!Object.prototype.hasOwnProperty.call(obj, i)) { continue; }
            if ((typeof obj[i]) == 'object') {
                // flattenKeys[i] = obj[i];
                const flatObject = this.flattenObject(obj[i]);
                for (const j in flatObject) {
                    if (Object.prototype.hasOwnProperty.call(flatObject, j)) { continue; }
                    flattenKeys[i + '.' + j] = flatObject[j];
                }
            } else {
                flattenKeys[i] = obj[i];
            }
        }
        return flattenKeys;
    }

    downloadCS2V() {
        console.log(this.flattenObject(this.data));
    }

    getHeadings() {
        let headings = '';
        this.config.fieldSet.fields.forEach ( f => {
            if (f.visible.computer) {
                if (headings.length > 1) {
                    headings += ',';
                }
                headings += f.label;
            }
        })
        return headings + "\n";
    }

    getValue(f: Field, item: AbstractObject) {
        let value;
        if (f instanceof PicklistField) {
            value = f.getDisplayValue(item);
        } else if (f instanceof ButtonField) {
            value = f.getValue(item);
        } else if (f instanceof DateTimeField) {
            try {
                value = f.asDate(item);
                if (value) {
                    value = value.toISOString();
                }
            } catch (e) {
                console.error(e);
                value = '';
            }
        } else {
            value = f.getValue(item);
        }
        if (value === null || value === undefined) {
            return '';
        }
        console.log({f, value})
        if (typeof value === 'string' || value instanceof String) {
            // If a CSV value contains the delimiter, or quotes then it must be enclosed with quotes
            if (value.includes(",") || value.includes(`"`)) {
                if (value.includes(`"`)) {
                    // If the value is enclosed with quotes, then the quote mark itself needs to be 'escaped' with a quote
                    value = this.replaceAll(value, `"`, `""`); // Quotes in CSVs should be double quotes
                }
                value = `"${value}"`;
            }
        }
        return value;
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    getLineItems(content: string) {
        this.data.forEach ( item => {
            let lineItem = '';
            this.config.fieldSet.fields.forEach ( f => {

                if (f.visible.computer) {
                    if (lineItem.length > 0) {
                        lineItem += ','
                    }
                    lineItem += this.getValue(f, item);
                }
            });
            content += (lineItem + "\n");
        })
        return content;
    }

    downloadCSV() {
        console.log(this.config);
        let content = this.getHeadings();
        console.log(content);
        content = this.getLineItems(content);
        console.log(content);
        this.download(content, 'csv');
        /*
        const items = [];
        if (this.data.length > 0) {
            this.data.forEach(o => items.push(this.flattenObject(o)));
        }

        const replacer = (key, value) => {
            if (value === null) {
                return '';
            } else if (typeof value !== 'object') {
                return value;
            }
        }; // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        const csv = [
            header.join(','), // header row first
            ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n');
        console.log('Downloading');
        this.download(csv, 'csv');
        */
    }

    download(data: string, type: string) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/' + type + ';charset=UTF-8,' + encodeURIComponent(data));
        element.setAttribute('download', this.config.title + '.' + type);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click(); // simulate click
        document.body.removeChild(element);
    }
}
