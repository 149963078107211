/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';

import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PostService } from '../post.service';
import { UserService } from 'src/app/modules/user/user.service';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { Post } from 'src/app/model/post';
import { User } from 'src/app/model/user';
import { required, minChars, minWords } from 'src/app/shared/validators';

import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { NewButtonService } from 'src/app/shared/new-button/new-button.service';
import { Observable } from 'rxjs';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';

import { Privilege } from 'src/app/model/role';
import { Forum } from 'src/app/model/forum';
import { Field } from 'src/app/shared/field/Field';
import { ChatComponent } from 'src/app/shared/chat/chat-component/chat.component';
import { ChatField } from 'src/app/shared/chat/chat-field';
import { NavRoute } from 'src/app/shared/NavRoute';
import { uuid } from 'src/app/model/abstract-object';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { CtlHolderComponent } from '../../../../shared/form/ctl-holder/ctl-holder.component';
import { FormActionsComponent } from '../../../../shared/form/form-actions/form-actions.component';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { FormErrorComponent } from '../../../../shared/form/form-error/form-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormPicklistComponent as FormPicklistComponent_1 } from '../../../../shared/form/form-picklist/form-picklist.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarComponent } from '../../../user/avatar/avatar.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { CommsTemplateService } from '../../comms-template-page/comms-templates.service';
import { CommsTemplate, CommsTemplateType } from 'src/app/model/CommsTemplate';
import { MyInjector } from 'src/app/app.module';
import { MatDialog } from '@angular/material/dialog';
import { CommsTemplateUpdaterComponent } from '../../comms-template-page/comms-template-updater/comms-template-updater.component';
import { Area } from 'src/app/model/area';
import { AreaService } from 'src/app/modules/unit/area.service';

@Component({
    selector: 'app-post-item',
    templateUrl: './post-item.component.html',
    styleUrls: ['./post-item.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatCardModule, AvatarComponent, MatTooltipModule, CtlHolderComponent,
        FormPicklistComponent_1, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,
        FormErrorComponent, EditorComponent, FormActionsComponent, CtlHolderComponent, FromNowPipe, DatePipe]
})
//extends AbstractFormComponent
export class PostItemComponent {

    static readonly navRoute = new NavRoute('social/posts/:itemId', PostItemComponent, 'forum');

    focusItem: Post = new Post();
    currentUser: User = new User();
    privilege: Privilege = new Privilege();
    formMode = false;
    isNew = false;
    sending = false;

    areas: Area[] = [];
    users: User[] = [];

    chatField: ChatField = ChatComponent.make(this.postService, { formRow: 2, visible: Field.noShow });
    chatControl = this.chatField.makeControl();

    forumField = FormPicklistComponent.make('Channel To Post This Entry Into', 'forum', null,
        { items: [] }, { validators: [required] }
    );

    areaField = FormPicklistComponent.make('Area', 'areaId', 'area',
        { refreshes: ['coreId'], items: this.areas, allowSelectNone: true }, { formColumn: 1 });

    coreField = FormPicklistComponent.make('Core', 'coreId', null, { items: [], allowSelectNone: true }, {
        formColumn: 1,
        refresh: (o: Area, control: AppPicklistControl) => {
            if (o) {
                control.field.setPicklistItems(o.cores);
                if (control.value && !o.cores.find( c => c.id === control.value)) {
                    control.setValue(null);
                }
            }
        }
    });
    authorField = FormPicklistComponent.make('Author', 'authorId', null, { items: this.users }, { readonly: true });

    public pageForm = new UntypedFormGroup({
        title: new UntypedFormControl('', [required, minChars(12), minWords(3)]),
        body: new UntypedFormControl('', [required, minChars(50), minWords(10)]),
        imageId: new UntypedFormControl(''),
        forum: this.forumField.makeControl(),
        author: this.authorField.makeControl(),
        areaId: this.areaField.makeControl(),
        coreId: this.coreField.makeControl(),
    });

    attachmentGrid = AttachmentGridComponent.make(this.postService);
    attachmentControl = this.attachmentGrid.makeControl();
    currentTemplate : CommsTemplate;

    editorOptions = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        min_height: 300,
        menubar: false,
        relative_urls: false,
        remove_script_host: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        // content_css: 'dark',
        // skin: 'oxide-dark', // For Dark themes?
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen autoresize',
            'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
            'formatselect | chooseTemplate | bold italic superscript subscript | \
      table | outdent indent alignment | \
      bullist numlist | removeformat | link image | undo redo | code | help',
        setup: (ed) => {
            ed.on('init', () => {
                this.pageForm.controls.body.markAsPristine();
            });
            /* example, adding a group toolbar button */
            ed.ui.registry.addGroupToolbarButton('alignment', {
                icon: 'align-left',
                tooltip: 'Alignment',
                items: 'alignleft aligncenter alignjustify alignright'
            });
            ed.ui.registry.addMenuButton('chooseTemplate', {
                text: 'Template',
                fetch: this.setupTemplateMenu.bind(this, ed),
            });
        }
    };

    postId: uuid = 0;

    imageId = null;

    locationRef: Location;
    currentChannelFilter: uuid;

    protected dataService: PostService;
    protected objectType = 'Post';
    constructor(private route: ActivatedRoute,
        private router: Router,
        location: Location,
        protected confirmDialogService: ConfirmDialogService,
        public postService: PostService,
        protected userSvc: UserService,
        private cds: ConfirmDialogService,
        private currentUserService: CurrentUserService,
        public screenSvc: IsNarrowService,
        private commsTmplSvc: CommsTemplateService,
        private areaSvc: AreaService,
        nbs: NewButtonService) {

        this.locationRef = location; // for back button
        route.params.subscribe(params => {
            this.currentChannelFilter = params.channelId;
            if (params.itemId === 'NEW') {
                this.onNew();
            } else {
                this.getPost();
            }
        });
        currentUserService.getCurrentUser().subscribe((user) => {
            this.currentUser = user
            this.privilege = User.privilege(this.currentUser, 'Post');
            if (this.privilege.put) {
                this.authorField.defaultReadonly = false;
                this.authorField.readonly = false;
            }
            this.forumField.setPicklistItems(currentUserService.getForums('canCreate'));
            if (this.forumField.picklist.items.length > 0) {
                //console.log({ pl: this.forumField.picklist, len: this.forumField.picklist.items.length });
                nbs.setupButton($localize`Post`, 'social/posts/NEW', true);
            }
        });
        this.areaSvc.get<Area>(true).subscribe( areas => {
            this.areas = areas
            this.areaField.setPicklistItems(areas);
        })
        this.userSvc.getUsers().subscribe ( users => this.users = users);
    }

    setEditorContent(ed, t: CommsTemplate) {
        this.currentTemplate = t;
        ed.setContent(t.content);
    }
    saveTemplate(ed) {
        const dialog = MyInjector.instance.get(MatDialog);
        dialog.open(CommsTemplateUpdaterComponent, {
            data: {
                type: CommsTemplateType.POST,
                template: this.currentTemplate,
                content: ed.getContent()
            }
        }).afterClosed().subscribe(tmpl => {
            if (tmpl.id !== this.currentTemplate.id) {
                this.currentTemplate = tmpl;
                ed.setContent(tmpl.content);
            }
        });
    }

    setupTemplateMenu(ed, callback) {
        const items = [];
        this.commsTmplSvc.get(true).subscribe((ts: CommsTemplate[]) => {
            ts.forEach(t => {
                if (t.type === CommsTemplateType.POST.id) {
                    items.push({
                        type: 'menuitem',
                        text: (this.currentTemplate?.id === t.id ? '* ' : '') + t.title,
                        onAction: this.setEditorContent.bind(this, ed, t),
                        disabled: this.currentTemplate?.id === t.id
                    })
                }
            });
            const ct = ed.getContent();
            if (ct && ct !== this.currentTemplate?.content) {
                items.push({ type: 'separator' }),
                    items.push({
                        type: 'menuitem',
                        text: 'Save Template',
                        onAction: this.saveTemplate.bind(this, ed)
                    });
            }

            callback(items);
        });
    }

    getPost(): void {
        /* Get Post for display, not all editting fields retrieved */
        this.isNew = false;
        const id = this.route.snapshot.paramMap.get('itemId');
        this.postService.getOne(id, null)
            .subscribe(post => {
                this.loadPost(post);
            });
    }

    loadPost(post: Post) {
        this.focusItem = post ? post : new Post();
        this.sending = false;
        if (post) {
            this.resetForm();
            this.chatField.setValue(post, false);
            this.chatField.chat.allowComments = User.hasForumPriv(this.currentUser, post.forumId, post.teamId, 'canComment');
            this.chatField.chat.allowReplies = this.chatField.chat.allowComments;

            this.attachmentGrid.setValue(post, this.attachmentGrid.readonly);
        }
        this.attachmentGrid.showSimple = true;
        this.attachmentGrid.showGrid = false;
        if (this.canEditPost() && !this.screenSvc.isPhone()) {
            this.attachmentGrid.readonly = false;
            this.attachmentGrid.show();
        } else {
            this.attachmentGrid.readonly = true;
            if (post?.attachments?.length <= 0) {
                this.attachmentGrid.hide();
            }
        }
    }

    canEditPost() {
        if (this.focusItem?.id === null) {
            return false; // no edit button needed on new ones, or before date retrieved...
        }
        return User.canEditPost(this.currentUser, this.focusItem);
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.focusItem && this.hasChanges()) {
            return confirm($localize`Your data has not been saved. Are you sure you want to leave and lose these changes?`);
        }
        return true;
    }

    hasChanges(): boolean {
        let hasChanges = false;

        for (const controlName of Object.keys(this.pageForm.controls)) {
            if (this.pageForm.controls[controlName].dirty) {
                hasChanges = true;
            }
        }

        return hasChanges;
    }

    resetForm() {

        this.postId = this.focusItem.id;

        this.pageForm.controls.forum.setValue(this.focusItem.forumId);
        this.pageForm.controls.forum.markAsPristine();

        this.pageForm.controls.author.setValue(this.focusItem.authorId);
        this.pageForm.controls.author.markAsPristine();

        //this.pageForm.controls.title.setValue(this.focusItem.title.raw ? this.focusItem.title.raw : this.focusItem.title.rendered);
        this.pageForm.controls.title.setValue(this.focusItem.title);
        this.pageForm.controls.title.markAsPristine();

        this.pageForm.controls.areaId.setValue(this.focusItem.areaId);
        this.pageForm.controls.areaId.markAsPristine();

        if (this.focusItem.areaId) {
            this.coreField.refresh(this.focusItem.area, this.pageForm.controls.coreId as AppPicklistControl);
        }

        this.pageForm.controls.coreId.setValue(this.focusItem.coreId);
        this.pageForm.controls.coreId.markAsPristine();

        //this.pageForm.controls.body.setValue(this.focusItem.content.raw ? this.focusItem.content.raw : this.focusItem.content.rendered);
        this.pageForm.controls.body.setValue(this.focusItem.content);
        this.pageForm.controls.body.markAsPristine();

        this.pageForm.controls.imageId.setValue(this.focusItem.imageId);
        this.pageForm.controls.imageId.markAsPristine();

        this.imageId = this.focusItem.imageId;

        this.pageForm.markAsPristine();

    }

    onNew(): void {
        this.formMode = true;
        this.isNew = true;
        const p = new Post();
        p.authorId = this.currentUserService.currentUser.id;
        this.loadPost(p);
    }

    notifyUsers() {
        const f: Forum = this.focusItem.forum;
        let msg = $localize`Send a notification email with a copy of your post to everyone in ${f.name} now?`;
        if (f.residents) {
            msg = $localize`Send a notification email with a copy of your post to all residents of ` + this.currentUser.currentTeam.name + ' now?';
        } else if (f.owners) {
            msg = $localize`Send a notification email with a copy of your post to all unit owners of ` + this.currentUser.currentTeam.name + ' now?';
        }

        this.cds.open($localize`Send Emails to everyone`, msg, () => {
            this.sending = true;
            this.postService.notify(this.focusItem);
        })
    }

    onEdit(): void {
        /* Make sure we have latest copy for Editting, including extra fields */
        this.postService.getOne(this.postId, null).subscribe(post => {
            if (post) {
                this.formMode = true;
                this.loadPost(post);
            }
        });
    }

    cancelChanges() {
        this.formMode = false;
        this.resetForm();
    }

    saveChanges(): void {
        const update: Post = new Post();
        //update.title.raw = this.pageForm.controls.title.value;
        //update.content.raw = this.pageForm.controls.body.value;
        update.title = this.pageForm.controls.title.value;
        update.content = this.pageForm.controls.body.value;
        update.imageId = this.imageId;
        update.forumId = this.pageForm.controls.forum.value;
        update.authorId = this.pageForm.controls.author.value;
        update.areaId = this.pageForm.controls.areaId.value;
        update.coreId = this.pageForm.controls.coreId.value;

        //update.status = 'publish';


        if (this.isNew) {
            /* New Item */
            this.postService.post(update).subscribe(response => {
                if (response) {
                    this.formMode = false;
                    this.resetForm();
                    this.router.navigate(['social/posts/' + response.id]);
                }
                //this.messageService.show($localize `Post has been successfully created`);
            });
        } else {
            update.id = this.postId;
            update.revision = this.focusItem.revision;
            this.postService.put(update).subscribe(response => {
                this.loadPost(response);
                this.formMode = false;
                //this.messageService.show($localize `Post has been saved`);
            });
        }
    }

    imageChanged(id) {

        this.imageId = id;
        this.pageForm.controls.imageId.setValue(id);
        this.pageForm.controls.imageId.markAsDirty();
    }

    goToList() {
        //this.locationRef.back();
        this.router.navigate(['social/posts', this.currentChannelFilter ? {channelId: this.currentChannelFilter} : {}]);
    }

    deleteObject(): void {
        this.confirmDialogService.open($localize`Delete this post`,
            $localize`Are you sure you want to delete this item and send it to the recycle bin`,
            (this.doDelete).bind(this),
            $localize`Delete`);
    }

    doDelete(): void {
        this.dataService.delete<Post>(this.focusItem).subscribe(response => {
            if (response) {
                this.goToList();
            }
        });
    }
}
