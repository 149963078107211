/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { of } from 'rxjs';
import { FieldSet, LAYOUT_OPTIONS } from '../shared/form/field-set/field-set.component';
import { FormTextComponent } from '../shared/form/form-text/form-text.component';
import { FormConfig } from "../shared/form/FormConfig";
import { AbstractObject, uuid } from './abstract-object';
import { BCode } from './bcode';
import { Schedule } from './schedule';
import { Txn } from './txn';
import { FieldMaker } from '../shared/field/FieldMaker';
import { Action } from './action';
import { Memo } from './memo';

export class SupplierCategory extends AbstractObject {
    globalSupplierId: uuid;
    globalCategoryId: uuid;
    category: AbstractObject;
    constructor(o: Partial<Supplier> = {}) {
        super(o);
    }
}

export class SupplyCategory extends AbstractObject {
    constructor(o: Partial<Supplier> = {}) {
        super(o);
    }

    static pickDialogFormConfig(): FormConfig {
        return new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FieldMaker.id(),
                    FieldMaker.rev(),
                    FormTextComponent.make('Name', 'name'),
                    FieldMaker.notes(),
                ],
                formLayout: LAYOUT_OPTIONS.singleCol,
            }),
            title: $localize`Supply Categories`,
            mode: 'new',
            objectFactory: () => of(new SupplyCategory()),
        });
    }
}

export class PreferredSupplier extends AbstractObject {
    name: string;
    notes: string;
    code: string;
    globalSupplierId: uuid;
    globalSupplier: Supplier;
    template: SupplierTemplate[] = [];
    transactions: Txn[] = [];
    txnWithPayments: Txn[] = [];
    actions: Action[] = [];
    memos: Memo[] = [];
    constructor(o: Partial<Supplier> = {}) {
        super(o);
    }
}

export class SupplierTemplate extends AbstractObject {
    bCodeId: uuid;
    bCode: BCode;
    scheduleId: uuid;
    schedule: Schedule;
    supplierId: uuid;

    constructor(o: Partial<Supplier> = {}) {
        super(o);
    }
}
export class Supplier extends AbstractObject {
    constructor(o: Partial<Supplier> = {}) {
        super(o);
    }
    name: string;
    address: string;
    email: string;
    phone: string;
    web: string;
    notes: string;
    VAT: string;
    IBAN: string;
    DUNS: string;
    categories: SupplierCategory[] = [];
    preferred: PreferredSupplier;

    static categoryList(o: Supplier) {
        let ret = '';
        for (const c of o.categories) {
            if (ret.length > 0) {
                ret += ', ';
            }
            ret += c.category.name;
        }
        return ret;
    }
}
