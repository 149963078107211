/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { NavRoute } from 'src/app/shared/NavRoute';
import { required } from 'src/app/shared/validators';
import { CommsTemplateService } from './comms-templates.service';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { CommsTemplate, CommsTemplateType } from 'src/app/model/CommsTemplate';
import { of } from 'rxjs';
import { FormPageComponent } from 'src/app/shared/form/form-page/form-page.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';

@Component({
  selector: 'app-comms-template-page',
  standalone: true,
  imports: [FormPageComponent],
  templateUrl: './comms-template-page.component.html',
  styleUrl: './comms-template-page.component.scss'
})
export class CommsTemplatePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('social/comms', CommsTemplatePageComponent, 'model_training')

    dataSvc = inject(CommsTemplateService);

    editor = FormRichTextComponent.make('Content', 'content', { formColumn: 2, validators: [required], availReplacements: [] });
    typePickList = FormPicklistComponent.make('Type', 'type', null, {
        items: CommsTemplateType.TYPES, refreshes: [(t: CommsTemplateType) => {
            if (t.replacements?.length > 0) {
                this.editor.availReplacements = t.replacements.slice()
            } else {
                this.editor.availReplacements = [];
            }
        }]
    }, { validators: [required] });

    config = new FormConfig({
        navRoute: CommsTemplatePageComponent.navRoute,
        title: $localize`Communication Templates`,
        help: $localize`Templates for communications`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormTextComponent.make('Title', 'title', { validators: [required], cellOpts: { width: '25%' } }),
                this.editor,
                this.typePickList,
                FormPicklistComponent.make('Parent', 'parentId', 'parentId',
                    { service: this.dataSvc, optionDisplayValue: (o) => o.title },
                    {hint: $localize `Allows for example questions templates, to be linked to a ballot template`}
                ),
            ],
            formLayout: [{ cells: [{ width: '20%' }, { width: '60%' }, { width: '20%' }]}]
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new CommsTemplate()),
        beforeEdit: this.beforeEdit.bind(this)
    });

    beforeEdit(c: CommsTemplate) {
        const type = CommsTemplateType.TYPES.find( ct => ct.id === c.type);
        if (type?.replacements?.length > 0) {
            this.editor.availReplacements = type.replacements;
        } else {
            this.editor.availReplacements = [];
        }
        return c;
    }

}
