import { Component, inject, OnDestroy } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { MyUnitService } from '../my-unit.service';
import { Unit } from 'src/app/model/unit';
import { MatCardModule } from '@angular/material/card';
import { CurrencyPipe, DatePipe, NgTemplateOutlet, PercentPipe, formatPercent } from '@angular/common';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { Role } from 'src/app/model/role';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { AddPersonUnitDialogComponent } from '../../crm/add-person-unit-dialog/add-person-unit-dialog.component';
import { Schedule } from 'src/app/model/schedule';
import { combineLatest, Subscription } from 'rxjs';
import { CycleService } from '../../budget/cycle.service';
import { Cycle } from 'src/app/model/cycle';
import { MatIconModule } from '@angular/material/icon';
import { InvoiceService } from '../../txn/invoice.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { Txn } from 'src/app/model/txn';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { EditPersonComponent } from './edit-person/edit-person.component';
import { CurrentUserService } from '../../user/current-user.service';

@Component({
  selector: 'app-my-unit-page',
  standalone: true,
  imports: [MatCardModule, NgTemplateOutlet, MatTooltip, MatButtonModule, PercentPipe,
    CurrencyPipe, DatePipe, MatIconModule],
  templateUrl: './my-unit-page.component.html',
  styleUrl: './my-unit-page.component.scss'
})
export class MyUnitPageComponent implements OnDestroy {

    static readonly navRoute = new NavRoute('myunits', MyUnitPageComponent, 'account_balance');

    myUnitSvc = inject(MyUnitService);
    peopleSvc = inject(PersonService);
    cycleSvc = inject(CycleService);
    invoiceSvc = inject(InvoiceService);
    cds = inject(ConfirmDialogService);
    cus = inject(CurrentUserService);

    ownerStartDate: string = null;

    selectedPerson : PersonUnitRole = null;

    currentCycle : Cycle;

    dialog = inject(MatDialog);

    myUnit: Unit;
    myUnits: Unit[];
    hasResident = true;
    hasKeyholder = true;

    subscriptions: Subscription[] = [];

    ownerOnlyAddressTip = $localize `Owner who are not resident need to provide an address for billing and communications purposes`;

    constructor() {
        this.subscriptions.push(combineLatest([
            this.myUnitSvc.get(true), this.cycleSvc.get(true), this.cus.getCurrentUser()
        ]).subscribe(([units]) => {
            this.currentCycle = this.cycleSvc.getCurrentCycle();
            this.setupUnits(units as Unit[]);
        }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach( s => s.unsubscribe());
    }

    setupUnits(units: Unit[]) {

        this.myUnits = units;
        if (units.length >= 1) {
            this.selectUnit(units[0]);
        }
    }
    selectUnit(u: Unit) {
        this.myUnit = u;
        this.myUnitSvc.getOne(this.myUnit.id, null).subscribe((u: Unit) => {
            if (u) {
                this.loadUnit(u);
            }
        });
    }

    checkPerson(p: PersonUnitRole) {
        const gri = p.role.globalRoleId;
        p.person.address = this.replaceAll(p.person.address, String.fromCharCode(10), '<br/>');
        if (gri === Role.ROLE.OWNER_OCCUPIER.id || gri === Role.ROLE.RESIDENT.id) {
            this.hasResident = true;
        }
        if (gri === Role.ROLE.KEYHOLDER.id) {
            console.log('Has Keyholder...');
            this.hasKeyholder = true;
        }
        if (gri === Role.ROLE.OWNER.id && !p.person.address) {
            p.person['needsAddress'] = true;
        }
        if (gri === Role.ROLE.OWNER_OCCUPIER.id || gri === Role.ROLE.OWNER.id) {
            p['isOwner'] = true;
        }
        if (p.person.userId === this.cus.currentUser.id) {
            this.ownerStartDate = p.startDate;
        }
    }

    loadUnit(u: Unit) {
        this.myUnit = u;
        this.myUnit.currentTxn = [];
        this.myUnit.address = this.replaceAll(u.address, String.fromCharCode(10), '<br/>');
        this.hasKeyholder = false;
        this.hasResident = false;
        this.myUnit.people.forEach( p => this.checkPerson(p));

        Unit.calculatePaymentSchedule(this.myUnit, this.currentCycle);
        this.sortTransactions();

        this.myUnit.portions.forEach(p => {
            p.schedule.chargeType = Schedule.chargeTypes.find(ct => ct.id === p.schedule.chargeTypeId);
            p['tooltip'] = $localize`${this.myUnit.name} is charged ${formatPercent(p.percent, 'EN-ie', '1.3-3')}
                        of ${p.schedule?.name} Budget. The budget type is ${p.schedule.chargeType.name}.
                        ${p.schedule?.notes}`
        })
        this.myUnit.proposedBillingTotal = 0;
        this.myUnit.myProposedBillingTotal = 0;
        this.myUnit.billingItems.forEach ( bi => {
            bi.myTotal = 0;
            this.myUnit.proposedBillingTotal += bi.total;
            const p = this.myUnit.portions.find( o => o.scheduleId === bi.scheduleId);
            if (p) {
                bi.myTotal = bi.total * p.percent;
                this.myUnit.myProposedBillingTotal += bi.myTotal;
            }
        })
    }

    sortTransactions() {
        const ts = this.myUnit.transactions.sort( (a,b) => a.txnDate.localeCompare(b.txnDate));
        let balance = 0;
        ts.forEach( t=> {
            balance += (t.debit - t.credit);
            t.balance = balance;
            if (t.txnDate >= this.currentCycle.from) {
                this.myUnit.currentTxn.push(t);
            }
        })
    }


    replaceAll(str, find, replace) {
        if (str) {
            return str.replace(new RegExp(find, 'g'), replace);
        }
        return str;
    }

    editPerson(pur: PersonUnitRole) {
        this.dialog.open(EditPersonComponent, { data: pur }).afterClosed().subscribe( p => {
            if (p) {
                Object.assign(pur.person, p);
                this.checkPerson(pur);
            }
        });
    }

    addPersonToUnit() {
        this.dialog.open(AddPersonUnitDialogComponent, {data: {unit: this.myUnit}}).afterClosed().subscribe( r => {
            if (r) {
                this.myUnit.people.push(r);
                this.checkPerson(r);
            }
        })
    }

    removePersonFromUnit(pur: PersonUnitRole) {
        const t = $localize `Remove ${pur.person.fullName} from unit ${this.myUnit.name}`;
        const msg = $localize `They will no longer be able to access the OMC, or receive messages from the OMC`;
        this.cds.open(t,msg, () => {
            this.myUnitSvc.removePerson(this.myUnit.id, pur.personId).subscribe ( (u: Unit) => {
                if (u) {
                    console.log('Removed', u);
                    this.loadUnit(u);
                }
            })
        })
    }

    getInvoicePdf(t: Txn) {
        const m = "Download PDF of charges";
        const text = "Download PDF with full details of Service Charge Reference " + t.refNr;
        this.cds.open(m, text, () => {
            this.invoiceSvc.getPDF(t);
        })
    }

    getStatementPdf() {
        const m = "Download Full Statement PDF";
        const text = "Download PDF with full statement of account for this unit";

        this.cds.open(m, text, () => {
            this.myUnitSvc.getPDF(this.myUnit);
        })

    }

}
