/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/

import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { Observable, of } from "rxjs";

import { AbstractObject, uuid } from "src/app/model/abstract-object";
import { Action } from "src/app/model/action";
import { ActionGridComponent } from "./action-grid.component";

import { User } from "src/app/model/user";

import { ConfirmDialogService } from "../dialogs/confirmDialog";
import { EditDialogComponent } from "../dialogs/edit-dialog/edit-dialog.component";
import { AppFormControl } from "../form/app-form-control";
import { FieldSet } from "../form/field-set/field-set.component";
import { FormButtonComponent } from "../form/form-button/form-button.component";
import { FormDateTimeComponent } from "../form/form-date-time/form-date-time.component";
import { FormDateComponent } from "../form/form-date/form-date.component";
import { FormDurationComponent } from "../form/form-duration/form-duration.component";
import { FormPicklistComponent } from "../form/form-picklist/form-picklist.component";
import { FormTextAreaComponent } from "../form/form-text-area/form-text-area.component";
import { FormTextComponent } from "../form/form-text/form-text.component";
import { FormConfig } from "../form/FormConfig";
import { GridControl } from "../grid/grid-control";
import { GridField } from "../grid/grid-field";
import { required } from "../validators";
import { GridControlParameters } from "../grid/grid-control-parameters";
import { FormGroup } from "@angular/forms";
import { Field } from "src/app/shared/field/Field";
import { FieldMaker } from "../field/FieldMaker";
import { ActionService } from "src/app/modules/crm/action.service";
import { MyInjector } from "src/app/app.module";
import { OmcAgentService } from "src/app/modules/user/omc-agent-service";
import { PicklistField } from "../field/PicklistField";
import { OmcAgent } from "src/app/model/OmcAgent";
import { FormComboBoxComponent } from "../form/form-combo-box/form-combo-box.component";
import { Omc } from 'src/app/model/Omc';
import { OmcService } from "src/app/modules/agent/omc.service";
import { Unit } from "src/app/model/unit";
import { Person } from "src/app/model/person";
import { FormRichTextComponent } from "../form/form-rich-text/form-rich-text.component";
import { CurrentUserService } from "src/app/modules/user/current-user.service";
import { UserService } from "src/app/modules/user/user.service";
import { AttachmentListComponent } from "../form/file/attachment-list/attachment-list.component";

export type LinksTo = 'Request' | 'Unit' | 'Person' | 'Supplier' | 'FixedAsset'
export class ActionGridField extends GridField {
    requestId: uuid;
    unitId: uuid;
    personId: uuid;
    supplierId: uuid;
    fassetId: uuid;
    linksTo: LinksTo;
    omcId: uuid;
    teamId: uuid;

    attachFromAttribute = 'id';

    actionService = MyInjector.instance.get(ActionService);
    dialog = MyInjector.instance.get(MatDialog);
    cds = MyInjector.instance.get(ConfirmDialogService);
    currentUser = MyInjector.instance.get(CurrentUserService).currentUser;
    userService = MyInjector.instance.get(UserService);

    omcAgents: OmcAgent[] = [];
    omcs: Omc[] = [];

    tabIcon = 'lists';

    /** Want to be able to refresh the request screen, as the status is changed by the change to the action... */
    actionCompleteFn: () => void;

    readonly completedText = 'check_box_outline';
    readonly notCompletedText = 'check_box_outline_blank';

    units: Unit[] = [];
    people: Person[] = [];
    users: User[] = [];

    constructor(options: Partial<Field>) {
        const field = new Field({
            name: 'actions',
            label: 'Actions',
            value: 'actions',
            type: 'grid',
            sendServer: false,
            visible: Field.formOnly,
        }, options);

        const parms: GridControlParameters = {
            field,
            rowFactory: () => [],
        }
        super(parms);

        this.getTabChip = this.getActionChip;

        this.userService.getAgentUsers().subscribe((users: User[]) => this.users = users);

        //this.config.objFactory = this.upsertAction.bind(this);
    }

    setValue(item: AbstractObject, readonly: boolean) {
        if (this.linksTo === 'Request') {
            this.requestId = item.id;
        } else if (this.linksTo === 'Person') {
            this.personId = item.id;
            this.units.length = 0;
            const p = item as Person;
            p.units.forEach(pur => this.units.push(pur.unit));
            if (this.units.length === 1) {
                this.unitId = this.units[0].id;
            }
        } else if (this.linksTo === 'Supplier') {
            this.supplierId = item.id;
        } else if (this.linksTo === 'Unit') {
            this.unitId = item.id;
            this.people.length = 0;
            const u = item as Unit;
            u.people.forEach(pur => this.people.push(pur.person));
            if (item['ownerId']) {
                this.personId = item['ownerId'];
            }
        } else if (this.linksTo === 'FixedAsset') {
            this.fassetId = item.id
        }

        this.omcId = item['omcId'];
        this.teamId = item['teamId'];
        return super.setValue(item, readonly);
    }

    makeTabControl(relatedFormGroup: FormGroup) {
        this.relatedFormGroup = relatedFormGroup;
        return this.makeControl();
    }

    makeControl() {
        this.config.rowFactory = this.rowFactory.bind(this);
        this.config.objFactory = this.upsertAction.bind(this);
        this.config.newOptionText = $localize`Create New Action`;
        if (!this.currentUser.inAgency) {
            MyInjector.instance.get(OmcAgentService).get(true).subscribe(agents => {
                this.omcAgents.length = 0;
                agents.forEach((oa: OmcAgent) => this.omcAgents.push(oa));
            })
        } else {
            MyInjector.instance.get(OmcService).get(true).subscribe(omcs => {
                this.omcs.length = 0;
                omcs.forEach((omc: Omc) => this.omcs.push(omc));
            });
        }
        this.control = new GridControl(this);
        // Reset it, grid control overrides it...
        this.formControlFactory = ActionGridComponent.createComponent;

        return this.control;
    }
    /* This is called with null on new actions...
       The actual action object we edit will however be a copy fresh from server if editting
       or created later if a new action...
    */
    private getActionFields(action: Action): Field[] {
        const flds = [];

        const omcHolder = FieldMaker.idHolder('omcId');
        if (!this.currentUser.inAgency) {
            const agentField: PicklistField = FormPicklistComponent.make('Agent', 'agentTeamId', 'agentTeam',
                {
                    items: this.omcAgents, refreshes: [(oa: OmcAgent) => {
                        omcHolder.control.setValue(oa.omc.id, { emitEvent: false });
                    }]
                },
                { validators: [required], readonly: action ? true : false }
            );
            flds.push(agentField);
        }

        if (this.linksTo === 'Person') {
            const unitField = FormPicklistComponent.make('Related Unit', 'unitId', null, {
                items: this.units, allowSelectNone: true, optionDisplayValue: o => Unit.getFullName(o),
            }, { hint: 'If you wish to link the action to persons unit, select here, otherwise leave blank' });
            flds.push(unitField);
        } else {
            flds.push(FieldMaker.idHolder('unitId'));
        }

        if (this.linksTo === 'Unit') {
            const personField = FormPicklistComponent.make('Related Person', 'personId', null, {
                items: this.people, allowSelectNone: true, optionDisplayValue: (o) => o.fullName,
            }, { hint: 'If you wish to link the action to persons, select here, otherwise leave blank' });
            flds.push(personField);
        } else {
            flds.push(FieldMaker.idHolder('personId'));
        }

        flds.push(FieldMaker.id());
        flds.push(FieldMaker.rev());
        flds.push(FieldMaker.idHolder('requestId'));
        flds.push(FieldMaker.idHolder('supplierId'));
        flds.push(FieldMaker.idHolder('fassetId'));
        flds.push(FieldMaker.idHolder('teamId'));
        flds.push(omcHolder);

        flds.push(FormTextComponent.make('Title', 'title', { validators: [required], }));
        flds.push(FormDateComponent.make('Planned Date', 'plannedDate',
            { formColumn: 2, validators: [required] })
        );
        flds.push(FormComboBoxComponent.make('Owned By', 'ownedById', 'ownedBy',
            { items: this.users },
            { formColumn: 2, cellOpts: { minWidth: '60px' } }
        ));

        /*
                flds.push(FormComboBoxComponent.make('Owned By', 'ownedById', 'ownedBy',
                    { items: this.users, optionDisplayValue: (o) => {
                        const userName = o.userName ?  '(' + o.userName + ')' : '(No Login)';
                        return Person.fullName(o) + ' ' + userName;
                    }, optionValue: (o) => o.userId},
                    { formColumn: 2, cellOpts: { minWidth: '60px' } }
                ));
        */

        flds.push(FormDateTimeComponent.make('Completed', 'completedAt', { formColumn: 2 }));
        /* calculateValue: (a) => (a as Action).completedAt */

        flds.push(FormDurationComponent.make('Time Spent', 'timeSpent', { formColumn: 2 }));

        flds.push(FormRichTextComponent.make('Description', 'description', { cellOpts: { width: '50%' } }));

        if (action?.template) {
            flds.push(FormTextAreaComponent.make('Template Instructions', 'template.description',
                { readonly: true, cellOpts: { width: '50%' }, sendServer: false, disable: true }
            ));
        }

        const af = AttachmentListComponent.make('Attach File', 'attachments',
            { service: this.actionService, allowNew: true, smallAddBox: true },
            { formColumn: 2 }
        );
        if (action?.completedAt || this.readonly) {
            af.readonly = true;
            af.fileOpts.allowNew = false;
        }
        flds.push(af)
        return flds;
    }

    private rowFactory(action: Action = null): Field[] {
        const flds = [];

        if (!this.currentUser.inAgency) {
            flds.push(FormTextComponent.make('Agent', 'agentName', { readonly: true }));
        }
        //flds.push(FormButtonComponent.makeLink('Action', 'title', '/crm/actions/${id}'));
        const titleField = FormButtonComponent.makeTextButton('Title', 'title', (ctl: AppFormControl) => {
            this.upsertAction(action).subscribe(updated => {
                if (updated) {
                    const row = ctl.getRow();
                    row.refreshFrom(updated);
                }
            });
        });
        titleField.btnOpts.style = "background-color:blue";
        flds.push(titleField);

        flds.push(FormDateComponent.make('Planned Date', 'plannedDate', { readonly: true }));
        flds.push(FormTextComponent.make('Owned By', 'ownedByName', { readonly: true }));

        const readCheckbox = FormButtonComponent.make('Read', 'readAtCheckbox', {
            type: 'icon',
            calculateValue: (action) => (action as Action).ownerReadAt ? this.completedText : this.notCompletedText,
            disable: true,
        });
        flds.push(readCheckbox);

        flds.push(FormDateTimeComponent.make('Completed', 'completedAt', { readonly: true }));
        const completedAtCheckbox = FormButtonComponent.make('Done', 'completedAtCheckbox', {
            type: 'icon',
            calculateValue: (action) => (action as Action).completedAt ? this.completedText : this.notCompletedText,
            btnOpts: { clickMethod: this.markActionComplete.bind(this) }
        });

        const when = moment(action?.completedAt * 1000).fromNow();
        completedAtCheckbox.toolTip = action?.completedAt ? 'Completed : ' + when : 'Click to mark complete';
        flds.push(completedAtCheckbox);
        flds.push(FormDurationComponent.make('Time Spent', 'timeSpent', { readonly: true }));

        flds.push(FormTextAreaComponent.make('Description', 'description', { readonly: true }));

        if (action && action.template) {
            flds.push(FormTextAreaComponent.make('Template Instructions', 'template.description',
                { readonly: true, cellOpts: { width: '50%' }, sendServer: false, disable: true }
            ));
        }

        return flds;
    }

    markActionComplete(ctl: AppFormControl) {
        const row = ctl.getRow();
        const action = row.focus as Action;
        if (this.readonly) {
            console.log({field: this, ctl});
            this.cds.alert('Permissions Required', 'You do not have permission to update status of this action');
            return;
        }
        if (!action.completedAt) {
            this.cds.open('Confirm Completed',
                'Mark this action completed? You cannot undo this action',
                () => {
                    this.actionService.complete(action, this.currentUser).subscribe(action => {
                        if (action) {
                            row.refreshFrom(action);
                            if (this.actionCompleteFn) {
                                this.actionCompleteFn();
                            }
                        }
                    })
                }
            );
        } else {
            this.cds.alert('Cannot Mark Undone', 'Cannot undo completing an action, please add a comment to it or create a replacement action');
        }
    }
    /*
        editAction(ctl: AppFormControl) {
            this.upsertAction(ctl.getRow().focus as Action).subscribe(action => {
                if (action) {
                    ctl.getRow().refreshFrom(action);
                }
            });
        }

        addAction() {
            this.upsertAction().subscribe(o => {
                this.control.addRow(o, true, false);
            });
        }
    */
    upsertAction(action: Action = null) {
        if (this.relatedFormGroup.dirty) {
            this.cds.alert('Save First', 'Sorry, you need to save request before adding actions');
            return of(null);
        }

        // if (this.control.parent.dirty) {
        //     this.cds.alert('Save First', 'Sorry, you need to save before adding actions');
        //     return of(null);
        // }
        //if (this.page.form.formGroup.dirty) {
        //    this.cds.alert('Save First', 'Sorry, you need to save request before adding actions');
        //    return of(null);
        //}
        const fields = this.getActionFields(action);

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Action`,
                fieldSet: new FieldSet(
                    {
                        fields: fields,
                        formLayout: [{ cells: [{ width: '66%' }, { width: '33%' }] }]
                    }
                ),
                mode: action ? 'edit' : 'new',
                objectFactory: this.createNewAction.bind(this),
                readonly: action?.completedAt > 0 ? true : false,
                allowEdit: !this.readonly,
                allowNew: !this.readonly
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: this.actionService,
                    id: action ? action.id : null,
                    hideTabs: true,
                    height: 820,
                }
            }
        );

        return dialogRef.afterClosed();
        /*
        dialogRef.afterClosed().pipe(first()).pipe<Action>(map(o => {
            return o;
        }));
        */
    }

    createNewAction(): Observable<Action> {
        const a = new Action();
        a.requestId = this.requestId;
        a.unitId = this.unitId;
        a.supplierId = this.supplierId;
        a.fassetId = this.fassetId;
        a.personId = this.personId;
        a.omcId = this.omcId;
        a.teamId = this.teamId;
        if (this.currentUser.inAgency) {
            const omc = this.omcs.find(o => o.omcTeamId === this.teamId);
            if (omc) {
                a.omcId = omc.id;
            } else {
                alert('Omc Does not seem to be configured');
            }
        }
        a.plannedDate = new Date().toISOString().substring(0, 10);
        if (this.omcAgents.length === 1) {
            a.agentTeamId = this.omcAgents[0].id;
            a.agentTeam = this.omcAgents[0];
            a.omcId = this.omcAgents[0]['omc']['id']
        }
        return of(a);
    }

    getActionChip(actions: Action[]): string {
        if (Array.isArray(actions)) {
            const total = actions.length;
            if (total === 0) {
                return '';
            }
            const done = actions.filter((a) => a.completedAt).length;
            return '' + (total - done) + '/' + total;
        }
        return '';
    }
}
