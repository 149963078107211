/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Post } from 'src/app/model/post';
import { AbstractObject, uuid } from './abstract-object';
import { Team } from './team';
import { Privilege, Role } from './role';
import { PersonUnitRole } from './person-unit-role';
import { NavItem } from './sys/nav-item';
import { ForumPrivName, ForumRole } from './forum';
import { Omc } from './Omc';

export class Follower extends AbstractObject {
    userId: uuid;
}
export class Accepted {
    accepts: string;
    acceptedAt: number
}
export class User extends AbstractObject {
    /**
     * @deprecated Teams array replaced by active positions
     */
    teams: Team[];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    name: string;
    current_team_id: uuid;
    currentTeam: Team;

    activePositions: PersonUnitRole[];
    currentRole: Role;
    currentVersion: uuid;
    currentRoleId: uuid;
    email: string;
    //unitRoles: PersonUnitRole[] = [];
    navItems: NavItem[];
    modelToPath?: { [key: string]: string }
    pathToModel?: { [key: string]: string }
    // Ugly but availableOmcs set in userNavButton which always gets called
    inAgency: { id: uuid, teamId: uuid, name: string, availableOmcs: Omc[]}
    inOmc: Omc;
    accepts: Accepted[] = [];

    //url: string;
    //description: string;
    //link: string;
    //slug: string;
    /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match,  */
    avatar: string;
    //avatar_urls: Avatars = {} as Avatars;
    //capabilities: Capabilities = {};
    //meta: [];
    environmentName?: string;
    environmentComment?: string;

    forumRoles?: ForumRole[] = [];


    constructor(o: Partial<User> = {}) {
        super(o);
    }

    static TermsOfService = 'termsOfService';

    static getAgentRole(user: User, teamId: uuid) {
        const agentRole = user.currentRole.agentRoles?.find(o => o.omcTeamId === teamId);
        if (!agentRole || !agentRole?.agentPrivs) {
            console.log('User has no agent role for team ', { user, teamId });
        }
        return agentRole;
    }

    static getActiveTeamName(user: User, teamId: uuid) {

        if (teamId) {
            const ar = User.getAgentRole(user, teamId);
            if (ar) {
                console.log(ar);
                return ar.omcName;
            } else {
                return 'Unknown Team';
            }
        } else {
            return user.currentTeam?.name;
        }
    }

    static getPathPrivilege(user: User, path: string, forceTeamId: uuid = null): Privilege {
        const model = user.pathToModel[path];
        let retPrivilege = null; // Should this be empty privilege returned?

        if (!model) {
            console.error('PathToModel not declared for path ' + path, user);
            retPrivilege = new Privilege();
        } else {
            retPrivilege = User.getModelPrivilege(user, model, forceTeamId);
        }
        return retPrivilege;
    }
    static getModelPrivilege(user: User, model:string, forceTeamId: uuid = null) {
        let retPrivilege = null; // Should this be empty privilege returned?

        if (!forceTeamId || forceTeamId === user.current_team_id) {
            if (user?.currentRole) {
                retPrivilege = user.currentRole?.privileges.models[model];
            } else {
                console.log('Permission checked without user role available')
            }
        } else {
            const agentRole = User.getAgentRole(user, forceTeamId)
            if (agentRole?.agentPrivs) {
                retPrivilege = agentRole?.agentPrivs.models[model];
            }
        }

        if (!retPrivilege) {
            return new Privilege();
        } else {
            return retPrivilege;
        }
    }

    static agentPrivilege(user: User, model: string, teamId): Privilege {
        const ar = User.getAgentRole(user, teamId);
        if (!ar) {
            return new Privilege();
        }
        const priv = ar.agentPrivs.models[model];
        return priv ? priv : new Privilege();
    }

    static canPostOwnSR(user: User) {
        return User.getModelPrivilege(user, 'MyServiceRequest').post;
    }

    static privilege(user: User, model: string): Privilege {
        const priv = user.currentRole?.privileges.models[model];
        return priv ? priv : new Privilege();
    }

    static hasWorkflowPrivilege(user: User, model: string, action: string | number): boolean {
        if (user.currentRole.privileges.workflows[model]) {
            return user.currentRole.privileges.workflows[model][action] ? true : false;
        } else {
            return false;
        }
    }

    static canEditPost(user: User, post: Post): boolean {
        if (!post || !user) {
            return false;
        } else if (post.authorId === user.id) {
            return true;
        } else if (User.hasForumPriv(user, post.forumId, post.teamId, 'canEdit')) {
            return true;
        } else {
            return false;
        }
    }

    /*
    * Client side, we only consider you have the privilege if you have it in your current role
    * This is to allow the UI to match your current privileges in the current role
    * Server side, you are considered to have the privilege, if you have it, regardless the current role
    * to reduce errors server side...
    */
    static hasForumPriv(user: User, forumId: uuid, teamId: uuid, priv: ForumPrivName) {
        const finder = (o: ForumRole) => o.forumId === forumId
            && o.teamId === teamId
            && o.roleId === user.currentRoleId
            && o[priv];
        if (user.forumRoles.find(finder)) {
            return true;
        } else {
            return false;
        }
    }

    static canHaveForumPriv(user: User, forumId: uuid, teamId: uuid, priv: ForumPrivName) {
        const finder = (o: ForumRole) => o.forumId === forumId && o.teamId === teamId && o[priv];
        if (user.forumRoles.find(finder)) {
            return true;
        } else {
            return false;
        }
    }
}
