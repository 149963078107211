<div>
    @if (!field.btnOpts.hide || !field.btnOpts.hide(focusItem)) {
    <button class="row-button" (click)="$event.stopPropagation();field.btnOpts.tableClickMethod(focusItem)" mat-icon-button
        [style]="field.tableIconStyler(focusItem)">
        @if(field.tableIconStyler(focusItem) === '') {
            <mat-icon color="primary">{{field.getValue(focusItem)}}</mat-icon>
        } @else {
            <mat-icon>{{field.getValue(focusItem)}}</mat-icon>
        }
    </button>
    }
</div>
