/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { DateHelper } from '../shared/dateHelper';
import { AbstractObject, uuid } from './abstract-object';
import { Person } from './person';
import { Role } from './role';
import { Team } from './team';
import { Unit } from './unit';

export class PersonUnitRole extends AbstractObject {
  personId: uuid;
  person: Person;
  unitId: uuid;
  unit: Unit;

  lastLoginAt: number;
  logins: number;

  team?: Team;
  personFullName: string;
  unitName: string;
  roleName: string;

  roleId: uuid;

  role: Role;
  startDate = '';
  endDate = '';
  constructor(o: Partial<PersonUnitRole> = {}) {
    super(o);
  }
  static isCurrent(o: PersonUnitRole) {
      if ((o === null) || o === undefined) {
          return false
      } else if (o.startDate && !DateHelper.beforeToday(o.startDate)) {
          return false;
      } else if ((o.endDate && DateHelper.beforeToday(o.endDate))) {
          return false;
      } else {
          return true;
      }
  }
}
