/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { ControlVisibility, Field } from 'src/app/shared/field/Field';
import { ServiceRequest } from 'src/app/model/serviceRequest';

import { User } from 'src/app/model/user';
import { ChatComponent } from 'src/app/shared/chat/chat-component/chat.component';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { IFormAction } from 'src/app/shared/form/form.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { OmcService } from '../../agent/omc.service';
import { CurrentUserService } from '../../user/current-user.service';
import { UserService } from '../../user/user.service';
import { ActionService } from '../action.service';
import { RequestService } from '../request.service';

import { ChatField } from 'src/app/shared/chat/chat-field';
import { ActionGridComponent } from 'src/app/shared/action/action-grid.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { NewServiceRequestComponent } from '../new-service-request/new-service-request.component';
import { requestCharts, requestViews } from './request-page-nav-config';
import { RequestTemplatesService } from '../request-templates.service';
import { TrendField } from 'src/app/shared/field/TrendField';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { MessageService } from 'src/app/shared/message.service';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { Person } from 'src/app/model/person';
import { AgentDataService } from '../../agent/agent-data.service';
import { NavOption } from 'src/app/model/sys/navOption';
import { UnitService } from '../../unit/unit.service';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { Unit } from 'src/app/model/unit';
import { Cycle } from 'src/app/model/cycle';
import { CycleService } from 'src/app/modules/budget/cycle.service'
import { AcceptRequestAction, AddActionFormAction, AddMemoAction, ReOpenRequestAction, ResolveRequestAction } from './resolve-request/ResolveRequestAction';
import { UnitDetailsAction } from "./UnitDetailsAction";
import { PersonDetailsAction } from "./PersonDetailsAction";
import { AddTemplateAction } from "./AddTemplateAction";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { OmcAgent } from 'src/app/model/OmcAgent';
import { OmcAgentService } from '../../user/omc-agent-service';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { GridField } from 'src/app/shared/grid/grid-field';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { ImageUploadRequestAction } from './image-upload-request/image-upload-request.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { MemoField } from "src/app/shared/memos/MemoField";
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { RequestBudgetEditor } from './requestBudgetEditor';
import { formatCurrency } from '@angular/common';
import { RequestParentEditor } from './requestParentEditor';
import { SendSupplierAction } from './send-supplier/send-supplier-action';
import { Router } from '@angular/router';
import { MyInjector } from 'src/app/app.module';

@Component({
    selector: 'app-service-request-page',
    templateUrl: './service-request-page.component.html',
    styleUrls: ['./service-request-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class ServiceRequestPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('crm/requests', ServiceRequestPageComponent, 'live_help')
        .setCharts(requestCharts)
        .setViews(requestViews)
        .setNewComponent(NewServiceRequestComponent);

    actions: IFormAction[] = [];

    users: User[] = [];

    isPhone = this.isNarrowSvc.isPhone();

    // Needs to be readonly until we can update the actions to match the new OMC...
    omcField: PicklistField = ServiceRequest.getOmcField().override({ readonly: true, disable: true }).hide();

    omcAgents: OmcAgent[] = [];
    agentField: PicklistField = FormComboBoxComponent.make('Agent', 'agentTeamId', 'agentTeam',
        { items: this.omcAgents },
        { disable: true }
    );
    teamField = FieldMaker.idHolder('teamId');

    attachmentGrid = AttachmentGridComponent.make(this.dataSvc, { formRow: 2 });

    subStatusField = ServiceRequest.getSubStatusField();

    actionGrid = ActionGridComponent.make('Request', {
        formRow: 2, actionCompleteFn: this.reloadForm.bind(this)
    }).hide();

    dataGrid = new GridField({
        field: { label: 'Data', value: 'dataFields', sendServer: false, tabIcon: 'tune' },
        rowFactory: () => [
            FormTextComponent.make('Name', 'name', { readonly: true, cellOpts: { style: 'text-align: right' } }),
            FormTextComponent.make('Value', 'value', { readonly: true, cellOpts: { width: '80%' } }),
        ]
    }).hide();

    budgetButton = FormButtonComponent.makeTextButton('Work Order ', 'budget', () => {
        new RequestBudgetEditor().editBudget(
            this.page.form.focusItem as ServiceRequest).subscribe(updated => {
                this.page.form.reloadContent(updated);
            });
    }, {
        calculateValue: (o: ServiceRequest) => {
            let amt = 0;
            o.requestBudget.forEach(rb => amt += rb.amount);

            if (!o.supplier && !amt) {
                return 'Edit';
            }
            const supplier = o.supplier ? o.supplier.name : $localize`Supplier Not Specified`;
            const amtText = amt ? formatCurrency(amt, 'EN-ie', '€') : '';

            return $localize`${amtText} for ${supplier}`;
        }, hint: $localize`Click to create or edit work order information`
    });

    parentField = FormButtonComponent.makeTextButton('Parent ', 'parent.title', () => {
        new RequestParentEditor().editParent(
            this.page.form.focusItem as ServiceRequest).subscribe(updated => {
                this.page.form.reloadContent(updated);
            });
    }, {
        hint: $localize`Click to edit parent request that this request depends on`,
        visible: Field.formOnly,
    });

    childGrid = new GridField({
        field:
            { label: 'Child Requests', value: 'childRequests', sendServer: false, tabIcon: 'account_tree' },
        rowFactory: (o: ServiceRequest) => [
            //FormTextComponent.make('Refn', 'refNr'),
            FormButtonComponent.makeLink('Ref', 'refNr', o ? ServiceRequestPageComponent.navRoute.getIdUrl(o.id) : '', {
                readonly: true, sendServer: false,
                cellOpts: { width: "5em", maxWidth: "5em" },
            }),
            FormTextComponent.make('Title', 'title', { readonly: true }),
            FormTextAreaComponent.make('Description', 'description', { readonly: true }),
            ServiceRequest.getStatusField(),
            //ServiceRequest.getSubStatusField(),
            ServiceRequest.getCreatedField(),
            ServiceRequest.getLastUpdatedField(),
            /*
            FormDateComponent.make('Date', 'txnDate', { cellOpts: { width: '2%' }, formColumn: 2 }),
            FormNumberComponent.make("Amount", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
            FieldMaker.notes(),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
            */
        ]
    }).hide();

    chatField: ChatField = ChatComponent.make(this.dataSvc, { formRow: 2 }, false).hide().allowComments();

    weekField = TrendField.make('Week Created', 'weekCreated', { cellOpts: { heading: 'Week' } });

    plannedField = FormPicklistComponent.make('Planned', 'planned', '',
        { items: [{ id: 0, name: 'No' }, { id: 1, name: 'Yes' }] }).hide();

    openField = FormPicklistComponent.make('Open', 'open', '',
        { items: [{ id: 0, name: 'No' }, { id: 1, name: 'Yes' }] }
    ).hide();

    propertyManagerField = FormPicklistComponent.make('Property Manager', 'omc.managerId', null,
        { items: this.users },
        {
            formColumn: 4, readonly: true, sendServer: false, disable: true,
            calculateValue: o => (o as ServiceRequest).omc?.managerId
        }
    ).hide();

    templateField = FormPicklistComponent.make('Template Request', 'templateId', null,
        { service: this.tmplService },
        { formColumn: 1, readonly: true, sendServer: false, disable: true }
    ).hide();

    plannedDateField = FormDateComponent.make('Planned Date', 'plannedDate',
        { valueChanges: this.setPlannedDate.bind(this), formColumn: 2 }
    );

    personField = FormComboBoxComponent.make('Person', 'personId', 'person',
        {
            items: [],
            optionDisplayValue: o => o?.fullName, allowSelectNone: true,
            refreshes: [this.refreshPerson.bind(this)]
        }, {
        formColumn: 2,
    });

    phoneField = FormPhoneComponent.make('Phone', 'person.phone', { disable: true, formColumn: 2, sendServer: false });
    emailField = FormEmailComponent.make('Email', 'person.email', { disable: true, formColumn: 2, sendServer: false });

    unitField = FormComboBoxComponent.make('Unit', 'unitId', 'unit',
        {
            items: [], optionDisplayValue: o => Unit.getFullName(o), allowSelectNone: true,
            refreshes: [(unit) => { this.unitSvc.refreshPeople(unit as Unit, this.personField, this.msgSvc) }]
        },
        { formColumn: 2, visible: Field.formOnly }
    );
    unitNameField = FormTextComponent.make('Unit Name', 'unit.name', {visible: Field.noForm, sendServer:false});

    newItem = { name: $localize`New Service Request`, basePath: ServiceRequestPageComponent.navRoute.newUrl } as NavOption

    memoField = new MemoField(this.dataSvc);

    openMonthPL = FormPicklistComponent.make('Open Month', 'monthOpened', 'openMonth',
        { items: [] }, {
        visible: Field.noShow, calculateValue: (o: ServiceRequest) => o.openMonth
    });

    config = new FormConfig({
        navRoute: ServiceRequestPageComponent.navRoute,
        title: $localize`Service Request`,
        help: $localize`Service Requests to get things done`,
        fieldSet: new FieldSet({
            fields: this.getAgencyFields(),
            formLayout: [
                { cells: [{ width: '50%' }, { width: '16%' }, { width: '16%' }, { width: '16%' }] },
            ]
        }),
        service: this.dataSvc,
        configReady: this.configReady,
        actions: this.actions,
        beforeEdit: this.beforeEdit.bind(this),
        newOptions: [
            //{ name: $localize `New Service Request`, basePath: 'crm/requests/NEW', params: {}},
            this.newItem,
            {
                name: $localize`New Templated Request`,
                basePath: ServiceRequestPageComponent.navRoute.newUrl,
                params: { templated: '' }
            }
        ],
        tabFields: [
            this.attachmentGrid,
            this.childGrid,
            this.dataGrid,
            this.chatField,
            this.actionGrid,
        ],
        tableHighlighter: (o) => (o as ServiceRequest).ownerReadAt ? false : true
    });

    setNewChildOption(parentId: uuid) {
        const childOption = {
            name: $localize`New Child Request`,
            basePath: ServiceRequestPageComponent.navRoute.newUrl, params: { parentId }
        }
        const existOption = this.config.newOptions.find(no => no.name === childOption.name);
        if (existOption) {
            const existIndex = this.config.newOptions.indexOf(existOption);
            this.config.newOptions.splice(existIndex, 1);
        }
        this.config.newOptions.push(childOption);
    }

    defaultVisibility: Map<string, ControlVisibility> = new Map();

    currentCycle: Cycle;

    constructor(public dataSvc: RequestService, public actionSvc: ActionService, private tmplService: RequestTemplatesService,
        omcSvc: OmcService, private msgSvc: MessageService,
        teamUserSvc: UserService,
        private agentDataSvc: AgentDataService, private msgService: MessageService,
        private dialog: MatDialog, private cds: ConfirmDialogService,
        private unitSvc: UnitService, private personSvc: PersonService,
        private isNarrowSvc: IsNarrowService,
        cycleSvc: CycleService,
        omcAgentSvc: OmcAgentService,
        currentUserSvc: CurrentUserService,
        @Optional() public dialogRef: MatDialogRef<NewServiceRequestComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {

        super();

        isNarrowSvc.detectVeryNarrow().subscribe(result => { this.isPhone = result; });

        this.subscriptions.push(combineLatest([
            currentUserSvc.getCurrentUser(),
            teamUserSvc.getAgentUsers(),
            cycleSvc.get(true),
            omcAgentSvc.get(true)
        ]).subscribe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([currentUser, users, cycles, omcAgents]) => {
                if (!currentUser.inAgency) { // Emails contain the link to here for agents/directors, but need to redirect owners!
                    const privSR = User.getModelPrivilege(currentUser, 'ServiceRequest');
                    if (!privSR.getIndex) {
                        console.log('No Privileges, trying myRequests instead...');
                        const r = MyInjector.instance.get(Router);
                        const url = window.location.pathname;
                        const id = url.substring(url.lastIndexOf('/') + 1);
                        r.navigate(['/myRequests/' + id]);
                        return;
                    }
                }

                this.currentUser = currentUser;
                this.currentCycle = cycleSvc.getCurrentCycle();

                for (const u of users as User[]) {
                    this.users.push(u);
                }

                for (const oa of omcAgents as OmcAgent[]) {
                    this.omcAgents.push(oa);
                }

                if (this.currentUser.inAgency) {
                    // If not in an agency, probably do not have permissions for these.
                    if (!this.isPhone) {
                        this.propertyManagerField.visible = Field.showAll;
                    }

                    this.omcField.visible = Field.showAll;
                    this.agentField.hide();
                    omcSvc.get(true).subscribe(omcs => this.omcField.picklist.items = omcs);

                    //this.newItem.params = { teamId: '?' };
                } else {
                    this.agentField.visible = Field.formOnly;
                    this.omcField.hide();

                    //this.newItem.params = { teamId: this.currentUser.current_team_id };
                }

                this.actionGrid.users = this.users;
                this.actionGrid.currentUser = this.currentUser;
                const memoIdx = this.config.tabFields.indexOf(this.memoField);
                if (this.currentUser.inAgency) {
                    if (memoIdx < 0) {
                        this.config.tabFields.push(this.memoField);
                    }
                } else {
                    if (memoIdx >= 0) {
                        this.config.tabFields = this.config.tabFields.splice(memoIdx, 1);
                    }
                }
                this.configReady.next(null);
            }
        ));
    }

    setPlannedDate(value) {
        if (value && this.actionGrid.control.value && this.actionGrid.control.gridRows().length > 0) {
            this.msgSvc.show(
                $localize`Remember, if you update the planned date of a request, you will need to update any actions as well`
            );
        }
    }

    refreshPerson(p: Person) {
        this.phoneField.control.setValue(p.phone, { emitEvent: false });
        this.emailField.control.setValue(p.email, { emitEvent: false });
    }

    reloadForm() {
        this.page.form.reloadForm();
    }

    getAgencyFields() {

        const reportedByField = ServiceRequest.getReportedByField(this.users);
        const ooField = ServiceRequest.getOOHoursField();
        const createdField = ServiceRequest.getCreatedField();
        const ownerField = ServiceRequest.getOwnerReadAtField();
        const lastUpdateField = ServiceRequest.getLastUpdatedField();
        const completedField = ServiceRequest.getCompletedField();
        const billableField = ServiceRequest.getBillableField();

        if (this.isPhone) {
            this.parentField.hide();
            this.subStatusField.hide();
            this.unitField.hide();
            this.unitNameField.hide();
            this.personField.hide();
            this.emailField.hide();
            this.phoneField.hide();

            reportedByField.hide();
            ooField.hide();
            createdField.hide();
            ownerField.hide();
            lastUpdateField.hide();
            completedField.hide();
            billableField.hide();

            this.propertyManagerField.hide();

        }

        return [
            FormTextComponent.make('Keyword Search', 'keyword', { sendServer: false }).hide(),
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.idHolder('jobId'),
            FieldMaker.idHolder('ballotId'),
            ServiceRequest.getOpenMonthField(),
            this.openMonthPL,
            ServiceRequest.getOpenWeekField(),
            ServiceRequest.getCloseMonthField(),
            ServiceRequest.getCloseWeekField(),
            this.teamField,
            this.openField,
            this.plannedField,
            this.omcField,
            this.agentField,
            ServiceRequest.getRefField(),
            ServiceRequest.getTitleField(),
            ServiceRequest.getDescriptionField(),
            this.parentField,
            this.budgetButton,
            ServiceRequest.getStatusField(),
            this.subStatusField,
            ServiceRequest.getCategoryField(),
            this.plannedDateField,
            this.unitField,
            this.unitNameField,
            this.personField,
            this.phoneField,
            this.emailField,
            reportedByField,
            ooField,
            ServiceRequest.getPriorityField(),
            ServiceRequest.getOwnedByField(this.users),
            billableField,

            createdField,
            ownerField,
            lastUpdateField,
            completedField,

            this.propertyManagerField,
            this.templateField,
            this.weekField,
            //this.templateActionsField,
            //this.attachmentGrid,
            //this.chatField,
            //this.actionGrid,
            //this.childGrid
        ]
    }

    toggleFields(show: boolean) {
        // Do we still need all this????
        console.warn('TOGGLING FIELDS... WHY?');
        this.config.fieldSet.fields.forEach(f => {
            if (!this.defaultVisibility.has(f.name)) {
                this.defaultVisibility.set(f.name, f.visible);
            }
            if (f.name === 'title' || f.name === 'description' || f.name === 'attachments') {
                // skip
            } else {
                if (show) {
                    f.visible = this.defaultVisibility.get(f.name);
                } else {
                    f.hide();
                }
            }
        })
    }

    getPicklists(sr: ServiceRequest) {
        if (sr.omcId !== null && sr.omc && sr.omc.omcTeamId) {
            this.agentDataSvc.getOmcUnits(sr.omc).pipe(first()).subscribe(units => {
                this.unitField.setPicklistItems(units);

            });

            if (sr.unitId && sr.unit) {
                this.unitSvc.refreshPeople(sr.unit, this.personField, this.msgSvc)
            } else {
                this.agentDataSvc.getOmcPeople(sr.omc).pipe(first()).subscribe(people => {
                    this.personField.setPicklistItems(people);
                    //this.personField.setValue(sr, this.unitField.readonly);
                });
            }
        } else {
            this.unitSvc.get(true).subscribe(units => {
                this.unitField.setPicklistItems(units);
                this.unitField.setComboDisplayValue(sr);
            });
            this.personSvc.get(true).subscribe(people => {
                this.personField.setPicklistItems(people);
                this.personField.setComboDisplayValue(sr);
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    flattenData(dataFields: AbstractObject[], item: any, prefix = '') {
        for (const prop in item) {
            if (typeof item[prop] === 'object') {
                this.flattenData(dataFields, item[prop], prefix + prop + '.')
            } else {
                dataFields.push({ name: prefix + prop, value: item[prop] } as unknown as AbstractObject)
            }
        }
    }
    beforeEdit(sr: ServiceRequest): ServiceRequest {

        if (!sr) {
            sr = new ServiceRequest(); // Request was not found... don't want errors...
        }
        sr.dataFields = [];
        if (sr.data) {
            this.flattenData(sr.dataFields, sr.data)
        }
        this.chatField.disable = false;
        this.attachmentGrid.disable = false;
        this.actionGrid.disable = false;
        this.actionGrid.sendServer = false; //this.templateActionsField.sendServer = false;
        //this.setEditTabFields();

        // Sort by date created descending
        sr.attachments = sr.attachments.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
        sr.actions = sr.actions.sort((a, b) => a.plannedDate > b.plannedDate ? 1 : -1);

        this.actions.length = 0;
        if (this.config.allowEdit) {
            this.actions.push(new ImageUploadRequestAction())
            this.actions.push(new AddTemplateAction(this.tmplService, this.dialog, this.cds, this.currentUser));
            this.actions.push(new AddActionFormAction(this.actionGrid.upsertAction.bind(this.actionGrid)));
            this.actions.push(new AddMemoAction(this.memoField));

            this.actions.push(new ResolveRequestAction(this.dataSvc, this.dialog));
            this.actions.push(new ReOpenRequestAction());
            this.actions.push(new AcceptRequestAction());
            this.actions.push(new UnitDetailsAction(this.unitField, this.teamField));
            this.actions.push(new PersonDetailsAction(this.personField, this.teamField, this.unitField));
            this.actions.push(new SendSupplierAction());
        }

        this.toggleFields(true);
        this.setNewChildOption(sr.id);

        this.actionGrid.setReadonly(!this.config.allowEdit);
        this.chatField.allowComments(this.config.allowEdit);

        // Not Complete --- this.actions.push(new CreatePersonAction);

        this.getPicklists(sr);

        return sr;
    }
}

