/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ReplaySubject, combineLatest, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { BankAccount } from 'src/app/model/bankAccount';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Person } from 'src/app/model/person';
import { Txn } from 'src/app/model/txn';
import { Unit } from 'src/app/model/unit';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormError } from 'src/app/shared/form/form-error/form-error.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { GridRow } from 'src/app/shared/grid/grid-row';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { validateTxnDate, required, minValue, maxValue } from 'src/app/shared/validators';
import { BCodeService } from '../../budget/bcode.service';
import { CycleService } from '../../budget/cycle.service';
import { PeriodService } from '../../budget/period.service';
import { UnitService } from '../../unit/unit.service';
import { CurrentUserService } from '../../user/current-user.service';
import { BankAccountService } from '../bank-account.service';
import { BankInService } from '../bank-in.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { UnitPageComponent } from '../../unit/unit-page/unit-page.component';
import { ButtonField } from 'src/app/shared/field/ButtonField';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { User } from 'src/app/model/user';

@Component({
    selector: 'app-bank-in-page',
    templateUrl: './bank-in-page.component.html',
    styleUrls: ['./bank-in-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class BankInPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute(Txn.TYPE.BANK_IN.code, BankInPageComponent, 'call_received').setViews((u: User) => {
        return [
            {
                id: 'currentYear',
                name: 'Current Year',
                filterFields: {
                    txnCycleId: u.currentTeam.currentPeriod?.cycleId,
                },
            }
        ]
    });

    personForReceipt: Person = null;

    readonly path = Txn.TYPE.BANK_IN.code

    cycleField: PicklistField = FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle',
        { service: this.cycleSvc }, { formColumn: 2, visible: Field.noShow }
    );

    periodField: Field = FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod',
        { service: this.periodSvc },
        {
            formColumn: 2, visible: Field.noShow,
            refresh: (o: Cycle, control: AppPicklistControl) => { if (o) { control.field.picklist.items = o.periods; control.setValue(null); } },
        }
    );

    txnDateField: Field = FormDateComponent.make('Transaction Date', 'txnDate', {
        cellOpts: { width: '2%' },
        validators: [validateTxnDate(this.cycleField, this.periodField)],
        valueChanges: this.dateValueChanges.bind(this)
    });

    amountField: Field = FormNumberComponent.make("Total", "debit",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formColumn: 2, valueChanges: this.totalValueChanges.bind(this) }
    );

    unallocatedField = FormNumberComponent.make("To be allocated", "unallocated",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formColumn: 2, visible: Field.formOnly, readonly: true, validators: [minValue(0), maxValue(0)] }
    );

    invoices: Txn[];

    units: Unit[] = [];
    allUnits: Unit[] = [];
    unitLinkBtns: ButtonField[] = this.makeUnitLinkButtons();

    childTxn = new GridField({
        field:
        {
            label: 'Allocation', value: 'details',
            visible: Field.formOnly, formRow: 2,
        },
        rowFactory: (o: Txn) => [
            //FieldMaker.id({visible:Field.showAll, cellOpts:{width:"9em"}}),
            //FieldMaker.rev(),
            FieldMaker.id(),
            FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
            FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
            FormTextComponent.make('bCodeId', 'bCodeId', { visible: Field.noShow }),
            FormTextComponent.make('personId', 'personId', { visible: Field.noShow }),

            FormTextComponent.make('txnDate', 'txnDate', { visible: Field.noShow }),
            FormTextComponent.make('txnCycleId', 'txnCycleId', { visible: Field.noShow }),
            FormTextComponent.make('txnPeriodId', 'txnPeriodId', { visible: Field.noShow }),
            FieldMaker.id({ value: 'relatedId', name: 'relatedId' }),
            FormButtonComponent.makeLink('Ref', 'related.refNr', o ? Txn.getTxnLink(o.related) : '', {
                readonly: true, sendServer: false,
                cellOpts: { width: "5em", maxWidth: "5em", minWidth: '5em' },
            }),
            /*
                        FormNumberComponent.make("Ref", "relatedId",
                            { disable: true, cellOpts: { width: "3em", maxWidth: "3em" } }
                        ),
            */
            FormNumberComponent.make("Ref", "relatedRev", {},
                { visible: Field.noShow, cellOpts: { width: "3em", maxWidth: "3em" } }
            ),
            FormDateComponent.make('Date', 'related.txnDate',
                { sendServer: false, disable: true, cellOpts: { minWidth: "6em" } }
            ),
            /*FormTextComponent.make('Cycle', 'related.txnCycle.name',
                { cellOpts: { minWidth: "6em" }, sendServer: false, disable: true }
            ), */
            FormPicklistComponent.make('Type', 'related.typeId', 'related.type',
                { items: Txn.TYPES },
                { sendServer: false, disable: true, cellOpts: { minWidth: "9em" } }
            ),
            FormPicklistComponent.make('Unit', 'unitId', 'unit',
                { items: this.units, optionDisplayValue: (o: Unit) => Unit.getFullName(o) },
                { disable: true, cellOpts: { minWidth: "6em" } }
            ),


            FormNumberComponent.make("Amount", 'related.debit',
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { cellOpts: { minWidth: "6em", maxWidth: "6em" }, sendServer: false, disable: true }
            ),
            FormNumberComponent.make("Outstanding", "related.outstanding",
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { cellOpts: { minWidth: "6em", maxWidth: "6em" }, sendServer: false, disable: true }
            ),
            FormNumberComponent.make("Allocate", "credit",
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { valueChanges: this.totalValueChanges.bind(this) }
            ),
            FormButtonComponent.make('Pay', '', {
                name: 'action', label: '', type: 'icon', sendServer: false,
                cellOpts: { heading: '', width: '1%' }, disable: o?.related?.outstanding === 0,
                calculateValue: () => 'assignment_turned_in',
                toolTip: 'Click to pay this item in full',
                btnOpts: { clickMethod: this.allocatePayment.bind(this) }
            }),

            FieldMaker.notes({ cellOpts: { width: "50%" } }),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow({ clickMethod: this.removeAllocationRow.bind(this) }),
        ]
    });

    configReady = new ReplaySubject<null>(1);
    configSetup = false;

    unitPickerField = FormComboBoxComponent.make('Unit', 'unitId', 'unit',
        { items: [], refreshes: [this.refreshInvoices.bind(this)], optionDisplayValue: (o: Unit) => Unit.getFullName(o) },
        { sendServer: false, formColumn: 3, }
    );

    config = new FormConfig({
        navRoute: BankInPageComponent.navRoute,
        title: Txn.TYPE.BANK_IN.name,
        help: $localize`Financial transactions`,
        readonly: true,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.idHolder('bankItemId'),
                FormNumberComponent.make('refNr', 'refNr'),
                FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
                FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
                FormPicklistComponent.make('Bank Account', 'bankAccountId', 'bankAccount',
                    { service: this.bankAccountSvc, refreshes: ['bCodeId'] },
                    { validators: [required] }
                ),
                FormPicklistComponent.make('Account', 'bCodeId', 'bCode', { service: this.bCodeSvc },
                    {
                        formColumn: 3, validators: [required], visible: Field.noShow,
                        refresh: (o: BankAccount, control) => { control.setValue(o?.bCodeId); }
                    }
                ),

                /* txtDateField Validator overwrites period info, need this order! */
                this.cycleField,
                this.periodField,
                this.txnDateField,
                this.amountField,

                FormComboBoxComponent.make('Person', 'personId', 'person',
                    {
                        service: this.personSvc,
                        optionDisplayValue: (o: Person) => Person.fullName(o),
                        allowSelectNone: true,
                        refreshes: [this.refreshUnitList.bind(this)]
                    }, { formColumn: 3 }
                ),

                this.unitPickerField,
                ... this.unitLinkBtns,
                FieldMaker.notes({ formColumn: 4 }),
                this.unallocatedField,
                this.childTxn,
            ],
            formValidator: this.validatePayment.bind(this),
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, width: '90%', pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newFactory.bind(this),
        configReady: this.configReady,
        beforeEdit: this.enhanceObject.bind(this),
        getConfirmSaveMessage: this.getConfirmSaveMessage.bind(this),
        newOptions: [
            { name: $localize`New ${Txn.TYPE.BANK_IN.name}`, basePath: `/${Txn.TYPE.BANK_IN.code}/NEW`, params: {} },
            { name: $localize`New ${Txn.TYPE.BANK_OUT.name}`, basePath: `/${Txn.TYPE.BANK_OUT.code}/NEW`, params: {} }
        ],
        pathEdit: (o: Txn) => Txn.getType(o.typeId).code + '/' + o.id
    });

    defaultBank: BankAccount = null;

    constructor(public dataSvc: BankInService, public bCodeSvc: BCodeService,
        protected activeRoute: ActivatedRoute,
        unitSvc: UnitService, public personSvc: PersonService,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService,
        protected bankAccountSvc: BankAccountService,
        msgSvc: MessageService, private currentUserSvc: CurrentUserService,
        @Optional() public dialogRef: MatDialogRef<BankInPageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {

        super();

        combineLatest([currentUserSvc.getCurrentUser(), this.bankAccountSvc.get(true), unitSvc.get(true),
            currentUserSvc.getDefaultBCodes()]).subscribe(
            ([currentUser, bankAccounts, units]) => {
                if (bankAccounts.length === 0) {
                    msgSvc.show(new MsgDef($localize`No bank accounts setup, you will not be able to enter receipts`, 'warn'));
                } else {
                    for (const acct of (bankAccounts as BankAccount[])) {
                        if (acct.defaultAcct) {
                            this.defaultBank = acct;
                        }
                    }
                    if (this.defaultBank === null) {
                        msgSvc.show(new MsgDef($localize`You should select a default bank account for receipts`, 'warn'));
                    }
                    units.forEach( (u: Unit) => this.allUnits.push(u));
                    this.units = this.allUnits.slice();
                    this.unitPickerField.setPicklistItems(units);
                }
                this.currentUser = currentUser;
                this.configReady.next(null);
                this.configSetup = true;
            });
    }

    getConfirmSaveMessage(): string {
        if (!this.personForReceipt) {
            // No pre attached files
            return $localize`Save without sending a receipt, cancel and select person to send receipt`;
        } else if (!this.personForReceipt.email) {
            return $localize`${this.personForReceipt.fullName} has no email address, will not be able to send receipt, save anyway?`;
        }
        return $localize`Save and send receipt to ${this.personForReceipt.fullName} (${this.personForReceipt.email})?`;
    }

    showUnitFieldBtn(unitId: uuid, pos: number, o: Txn) {
        const unit = this.units.find( u => u.id === unitId);
        o[this.unitNameVal(pos)] = unit.name;
        o[this.unitIdVal(pos)] = unitId;
        this.unitLinkBtns[pos].visible = Field.formOnly;
    }

    private unitLabel(pos: number) {
        return pos === 0 ? 'Unit ' : 'Unit - ' + (pos + 1);
    }
    private unitNameVal(pos: number) {
        return 'unit' + pos;
    }
    private unitIdVal(pos: number) {
        return 'unitId' + pos;
    }
    hideUnitLinkButtons() {
        this.unitLinkBtns.forEach( o => o.visible = Field.noShow);
    }

    makeUnitLinkButtons() {
        const ary = [];
        for (let i = 0; i <=5; i++) {
            const btn = FormButtonComponent.makeNavDetailButton(
                this.unitLabel(i), this.unitNameVal(i), this.unitIdVal(i), UnitPageComponent.navRoute
            ).override({ formColumn: 3, visible: Field.noShow });
            ary.push(btn);
        }
        return ary;
    }

    enhanceObject(o: Txn) {
        this.config.readonly = true;
        this.hideUnitLinkButtons();
        this.unitPickerField.visible = Field.noShow;
        let pos = 0;
        const done = {};
        o.details.forEach( dtl => {
            if (!done[dtl.unitId]) {
                this.showUnitFieldBtn(dtl.unitId, pos, o);
                done[dtl.unitId] = true;
                pos++;
            }
        })
        return o;
    }

    validatePayment() {

        if (this.childTxn.control && this.childTxn.control.controls.length === 0) {
            return FormError.reportError('paymentLineItems', $localize`Select person and/or unit to see charges to allocate payment against`);
        }

        const ufc = this.unallocatedField.control;
        if (ufc && ufc.value > 0.0000001) {
            return FormError.reportError('paymentUnallocated',
                $localize`Allocate payment or put on account the remaining ${ufc.field.formatValue(ufc.value)}`);
        }

        return null;
    }

    refreshUnitList(o: Person) { //, ctl: AppPicklistControl
        const personUnits: Unit[] = [];
        if (o && o.id) {
            this.personForReceipt = o;
            this.personSvc.getOne(o.id, new Person()).pipe(first()).subscribe(person => {
                for (const pur of person.personUnits) {
                    if (pur.unit) {
                        personUnits.push(pur.unit);
                    }
                }

                this.unitPickerField.setPicklistItems(personUnits);
                if (personUnits.length === 1) {
                    this.unitPickerField.setValue({unitId: personUnits[0].id, unit: personUnits[0]} as unknown as AbstractObject);
                    this.refreshInvoices(personUnits[0])
                } else {
                    this.unitPickerField.clearComboValue();
                }
            });
        } else {
            this.personForReceipt = null;
            this.unitPickerField.setPicklistItems(this.allUnits);
        }
    }

    allocatePaymentToRow(gridRow: GridRow) {
        const outstanding = gridRow.get('related_outstanding');
        const credit = gridRow.get('credit');
        const unallocated = this.page.form.formGroup.get('unallocated').value + credit.value;
        const actionBtn = (gridRow.get('action') as AppFormControl);
        if (actionBtn.value === 'backspace') {
            credit.setValue(0);
        } else {
            if (unallocated > 0 && outstanding.value > 0) {
                credit.setValue(Math.min(outstanding.value, unallocated));
            } else if (outstanding.value < 0) {
                credit.setValue(outstanding.value);
            } else if (gridRow.get('relatedId').value === null) {
                credit.setValue(unallocated);
            }
        }
    }

    setPaymentIconOnRow(gridRow: GridRow) {
        const outstanding = gridRow.get('related_outstanding').value;
        const credit = gridRow.get('credit').value;
        const unallocated = this.page.form.formGroup.get('unallocated').value;
        const actionBtn = (gridRow.get('action') as AppFormControl);

        if (credit < outstanding && unallocated > 0) {
            actionBtn.setValue('assignment_turned_in');
            actionBtn.field.disable = false;
            actionBtn.field.toolTip = 'Click to pay this item in full';
        } else if (credit > 0) {
            actionBtn.setValue('backspace');
            actionBtn.field.disable = false;
            actionBtn.field.toolTip = 'Click to remove payment allocation';
        } else {
            actionBtn.setValue('assignment_turned_in')
            if (gridRow.get('relatedId').value === null && unallocated > 0) {
                actionBtn.field.disable = false;
                actionBtn.field.toolTip = 'Click to take as payment on account';
            } else {
                actionBtn.field.disable = true;
                actionBtn.field.toolTip = 'No monies available to allocate';
            }
        }
    }

    allocatePayment(ctl: AppFormControl): void {
        if (ctl.isGridCell) {
            this.allocatePaymentToRow(ctl.getRow());
        }
    }

    removeAllocationRow(ctl: AppFormControl): void {
        if (ctl.isGridCell) {
            ctl.getRow().delete();
            this.totalValueChanges();
        }
    }

    dateValueChanges(): void {
        const allocations = (this.childTxn.control as GridControl).controls;
        if (allocations) {
            for (const txnRow of allocations) {
                txnRow.get('txnDate').setValue(this.txnDateField.control.value, { emitEvent: false });
                txnRow.get('txnCycleId').setValue(this.cycleField.control.value, { emitEvent: false });
                txnRow.get('txnPeriodId').setValue(this.periodField.control.value, { emitEvent: false });
            }
        }
    }

    totalValueChanges(): void {
        const fg: UntypedFormGroup = this.page.form.formGroup;
        const debit = fg.get('debit').value;
        let allocated = 0;
        const allocations = (this.childTxn.control as GridControl).controls;
        if (allocations) {
            for (const txnRow of allocations) {
                allocated += txnRow.get('credit').value;
            }
        }

        this.unallocatedField.control.setValue(debit - allocated, { emitEvent: false });
        for (const txnRow of allocations) {
            this.setPaymentIconOnRow((txnRow as GridRow));
        }
    }

    getCurrentPersonId(): number {
        const personChosen = this.page.form.formGroup.get('personId').value;
        if (personChosen) {
            return personChosen;
        } else {
            return null;
        }
    }

    /**
     * Sorts transactions in date order to pay oldest first
     * @param a Txn
     * @param b
     * @returns
     */
    txnSorter(a: Txn, b: Txn) {
        if (a.txnDate > b.txnDate) {
            //console.log(a.txnDate + ' after ' + b.txnDate);
            return 1; // > 0 - Put B before A
        } else if (a.txnDate < b.txnDate) {
            //console.log(a.txnDate + ' before ' + b.txnDate);
            return -1 // < 0 put A before B
        } else {
            // Same Date, go by order created, which is ID
            if (a.id > b.id) {
                return 1; // B was created before A, put B first
            } else {
                return -1 // A was created first.
            }
        }
    }

    refreshInvoices(unit: Unit) {
        //const filter = this.invoices.filter( txn => txn.unitId === unit.id);
        //console.log(unit, invoiceGrid);
        const personId = this.getCurrentPersonId();

        this.childTxn.deleteWhere( gr => {
            if(gr.get('credit').value === 0) {
                return true;
            }
            return false;
        });

        if (unit?.id) {
            this.dataSvc.getOutstanding(unit.id).subscribe(invoices => {
                const openTrans = invoices.sort(this.txnSorter); // Txn.TYPE.BANK_IN_ON_ACCT.id
                const credits = openTrans.filter(o => o.outstanding < 0);
                for (const inv of credits) {
                    this.createAllocation(inv, unit, personId);
                }
                const debits = openTrans.filter(o => o.outstanding > 0);
                for (const inv of debits) {
                    this.createAllocation(inv, unit, personId);
                }
                this.createUnallocated(unit, personId);
            });
        }
    }

    createAllocation(inv: Txn, unit: Unit, personId: uuid) {
        const newTxn = this.newAllocationTxn(unit.id, personId);
        newTxn.relatedId = inv.id;
        newTxn.relatedRev = inv.revision;
        newTxn.related = (inv as Txn);
        const newRow: GridRow = this.childTxn.control.addRow(newTxn, true, false);
        this.allocatePaymentToRow(newRow);
    }

    createUnallocated(unit: Unit, personId: uuid) {
        const newTxn = this.newAllocationTxn(unit.id, personId);
        newTxn.notes = 'Unallocated on account';
        //this.currentUser.currentTeam.overpaidChargeId;
        newTxn.bCodeId = this.currentUserSvc.getDefaultBCode('Debtors').bCodeId;
        newTxn.typeId = Txn.TYPE.BANK_IN_ON_ACCT.id;
        newTxn.type = Txn.TYPE.BANK_IN_ON_ACCT;
        newTxn.relatedId = null;
        newTxn.related = new Txn();
        newTxn.related.unitId = unit.id;
        newTxn.related.unit = unit;
        newTxn.related.typeId = Txn.TYPE.BANK_IN.id;
        newTxn.related.type = Txn.TYPE.BANK_IN;
        const newRow = this.childTxn.control.addRow(newTxn, true, false);
        this.allocatePaymentToRow(newRow);
    }

    newAllocationTxn(unitId, personId): Txn {
        const newTxn = new Txn();
        newTxn.ledgerId = Txn.LEDGER.AR.id;
        newTxn.ledger = Txn.LEDGER.AR;
        newTxn.typeId = Txn.TYPE.BANK_IN_ALLOC.id;
        newTxn.type = Txn.TYPE.BANK_IN_ALLOC;
        newTxn.bCodeId = this.currentUserSvc.getDefaultBCode('Debtors').bCodeId;
        newTxn.txnDate = this.txnDateField.control.value;
        newTxn.txnCycleId = this.cycleField.control.value;
        newTxn.txnPeriodId = this.periodField.control.value;

        newTxn.unitId = unitId;
        newTxn.personId = personId;

        return newTxn;
    }

    newFactory(): Observable<Txn> {
        const txn = new Txn();
        this.personForReceipt = null;

        txn.ledgerId = Txn.LEDGER.AR.id;
        txn.ledger = Txn.LEDGER.AR;

        txn.typeId = Txn.TYPE.BANK_IN.id;
        txn.type = Txn.TYPE.BANK_IN;

        txn.bankAccountId = this.defaultBank.id;
        txn.bankAccount = this.defaultBank;

        txn.bCodeId = this.defaultBank.bCodeId;

        txn.txnDate = moment().toISOString().substring(0, 10);
        //validateTxnDate(this.cycleField, this.periodField)(this.)

        this.config.readonly = false;
        this.hideUnitLinkButtons();
        this.unitPickerField.visible = Field.formOnly;

        if (this.dialogOptions) {
            if (this.dialogOptions['item']) {
                txn.debit = this.dialogOptions['item']['transactionAmount'];
                txn.bankItemId = this.dialogOptions['item']['id'];
            }
            if (this.dialogOptions['unit']) {
                txn.unitId = this.dialogOptions['unit']['id'];
            }
            if (this.dialogOptions['person']) {
                txn.personId = this.dialogOptions['person']['id'];
            }

            window.setTimeout(() => {
                if (this.dialogOptions['unit']) {
                    this.refreshInvoices(this.dialogOptions['unit'])
                }
                if (this.dialogOptions['person']) {
                    this.refreshUnitList(this.dialogOptions['person'])
                }

                this.totalValueChanges();

                this.amountField.control.markAsDirty();
                this.amountField.control.markAsTouched();
            }, 50);
        }

        return of(txn);
    }

}
