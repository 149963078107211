/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params, convertToParamMap, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Privilege } from 'src/app/model/role';
import { TableView } from 'src/app/model/sys/nav-item';
import { User } from 'src/app/model/user';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { AbstractHttpService } from '../../abstract-http.service';
import { ChartAnalysis } from '../../chart/chart-analysis';
import { Field } from '../../field/Field';
import { CanComponentDeactivate } from '../../guards/can-deactivate.guard';
import { IsNarrowService } from '../../is-narrow.service';
import { NavigationService } from '../../navigation/navigation.service';
import { NewButtonService } from '../../new-button/new-button.service';
import { TableComponent } from '../../table/table.component';
import { AbstractPageComponent } from '../abstract-page.component';
import { FormComponent, FormMode } from '../form.component';
import { FormConfig } from "../FormConfig";
import { MyInjector } from 'src/app/app.module';
import { MessageService, MsgDef } from '../../message.service';
import { ChartAnalysisComponent } from '../../chart/chart-analysis/chart-analysis.component';
import { FilterComponent } from '../../table/filter/filter.component';
import { TablePageHeaderComponent } from '../../table/table-page-header/table-page-header.component';
import { DialogBannerComponent } from '../../dialogs/dialog-banner-component';

@Component({
    selector: 'app-form-page',
    templateUrl: './form-page.component.html',
    styleUrls: ['./form-page.component.scss'],
    standalone: true,
    imports: [TablePageHeaderComponent, FormComponent, FilterComponent, ChartAnalysisComponent, TableComponent, DialogBannerComponent]
})
export class FormPageComponent implements CanComponentDeactivate, AfterViewInit, OnInit, OnDestroy {

    @Input() itemId: uuid;
    @Input() dialogRef: MatDialogRef<AbstractPageComponent>;
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild(FormComponent) form: FormComponent;

    @Input() title: string;
    @Input() help: string;
    @Input() config: FormConfig;
    @Input() drillDown = true;

    dialogWidth: number;
    dialogHeight: number;

    forceTeamId: uuid;

    tableRefresh: Subject<AbstractObject[]> = new Subject();

    chartOption: 'none' | 'hidden' | 'shown' = 'none';

    currentView: TableView = null;

    //isNarrow = true;
    //columns: Field[] = [];
    mode: FormMode;
    data: AbstractObject[] = [];
    initCompleted = false;
    /** Incoming parameters from the URL */
    currentParams: Params;
    /** Outgoing params to use when requesting the data */
    requestParams: HttpParams;

    dataSvc: AbstractHttpService;

    tableHeight = 500;
    isPhone = false;
    isPrint = false;

    subscriptions: Subscription[] = [];

    //pageForm: FormGroup = new FormGroup({});
    /*
    * The name of the object to refer to on screen, remember to localize it in your declaration with $localize
    */
    //abstract title: string;


    constructor(protected router: Router, protected activeRoute: ActivatedRoute,
        protected titleService: Title, private currentUserSvc: CurrentUserService, private navSvc: NavigationService,
        private isNarrowSvc: IsNarrowService, private navigationService: NavigationService,
        protected newButtonService: NewButtonService) {

        this.tableHeight = this.isNarrowSvc.screenHeight;
        this.dialogWidth = (isNarrowSvc.screenWidth * .85) - 110;
        this.dialogHeight = (isNarrowSvc.screenHeight * .75) - 40;
        this.subscriptions.push(isNarrowSvc.detectVeryNarrow().subscribe(result => { this.isPhone = result; }));

    }

    @HostListener('window:beforeunload')
    beforeUnload() {
        if (this.form) {
            return !this.form.focusItem || !this.form.hasChanges();
        }
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.form ? this.form.canDeactivate() : true;
    }

    toggleCharts() {
        this.table.toggleCharts();
        this.refreshCharts();
    }

    showCharts() {
        if (this.config.charts && !this.isPhone && this.config?.mode === 'list' && !this.table?.hideCharts) {
            return true;
        }
        return false;
    }

    printForm() {
        console.log('printForm');
    }

    setIsPrinting(yesNo: boolean) {
        if (this.config.mode === 'list') {
            this.table.isPrint = yesNo;
        } else {
            this.form.isPrint = yesNo;
        }
        this.isPrint = yesNo;
    }

    printTable() {
        const isWide = this.navSvc.navComponent.isWide;
        if (isWide) {
            this.navSvc.navComponent.isWide = false;
            this.navSvc.navComponent.setMenuStyle();
        }

        this.setIsPrinting(true);

        window.onafterprint = () => {
            this.setIsPrinting(false);
            if (isWide) {
                this.navSvc.navComponent.isWide = true;
                this.navSvc.navComponent.setMenuStyle();
                if (this.config.mode === 'list') {
                    this.refreshCharts();
                }
            }
        }
            ;
        window.setTimeout(() => {
            window.print()
        }, 50);
        //window.print();

    }

    actuallyRefreshCharts() {
        if (this.showCharts()) {
            this.config.charts.refresh(this.data, this.navigateUrl.bind(this));
        }
    }
    refreshCharts() {
        setTimeout(() => {
            this.actuallyRefreshCharts()
        }, 200);
    }

    setLocalPrivs() {
        const model = this.currentUserSvc.currentUser.pathToModel[this.config.navRoute.url];
        const p = User.privilege(this.currentUserSvc.currentUser, model);
        this.config.forTeamName = null;
        this.setConfigPrivs(p);
    }

    setConfigPrivs(p: Privilege) {
        if (this.config.allowNew) {
            this.config.allowNew = p.post;
        }
        if (this.config.allowDelete) {
            this.config.allowDelete = p.delete;
        }
        if (this.config.allowEdit) {
            this.config.allowEdit = p.put;
        }
    }

    setAgentPrivs() {
        /* Guessing privileges for now,
        ideally would check, but server will verify privs anyway worst case show wrong buttons
        */
        const user = this.currentUserSvc.currentUser;
        const forceTheTeamId = this.getForceTeamId();
        const agentRole = user.currentRole.agentRoles.find(o => o.omcTeamId === forceTheTeamId);
        if (!agentRole) {
            const msgSvc = MyInjector.instance.get(MessageService)
            msgSvc.show(new MsgDef('Agent Data Not Correctly Configured', 'warn'));
            console.error("No agent role defined", {
                user,
                currentRole: user?.currentRole, agentRoles: user?.currentRole?.agentRoles, forceTheTeamId
            })
            this.config.forTeamName = 'Unconfigured OMC Setup';
        } else {
            this.config.forTeamName = agentRole.omcName;
        }


        const privilege = User.getPathPrivilege(user, this.config.navRoute.url, forceTheTeamId);
        this.setConfigPrivs(privilege);
    }
    ngOnInit() {
        if (!this.config.navRoute) {
            console.warn('Config is missing navRoute', {page: this, config: this.config});
            throw "Cannot continue, config has no NavRoute";
        }
        this.config.navRoute.configureUserViews(this.currentUserSvc.currentUser);
        this.subscriptions.push(this.activeRoute.params.subscribe(params => this.setUp(params)));
        this.dataSvc = this.config.service;
    }

    ngAfterViewInit() {
        this.initCompleted = true;
        if (this.currentParams) {
            this.refreshTable();
        }
    }

    ngOnDestroy() {
        this.newButtonService.clear();
        this.subscriptions.forEach( s => s.unsubscribe());
    }

    refreshTable(): void {
        if (this.config.mode === 'list') {
            this.table.loadingData = true;
            this.table.clearData();

            this.dataSvc.get<AbstractObject>(false, this.requestParams, this.forceTeamId)
                .pipe(first()).subscribe(data => {
                    this.data = this.config.beforeList(data, this.requestParams);
                    this.table.forceTeamId = this.forceTeamId;
                    this.table.loadData(this.data);
                    this.refreshCharts();
                    this.config.setTeam(this.forceTeamId);
                });
        }
    }

    navigateUrl() {
        const parms = {};
        if (this.forceTeamId) {
            parms['_forceTeam'] = this.forceTeamId;
        }

        for (const field of this.config.fieldSet.fields) {
            if (field.filter.active) {
                if (field.filter.value !== 'any' && field.filter.value != 'any%') {
                    const value = field.filter.value;
                    if (field.name !== 'keyword' && field.type === 'text' && (typeof value === 'string')
                        && !['ANY', 'NULL', 'NOT NULL'].includes(value.toUpperCase()) && !value.includes('%')) {
                        field.filter.value += '%';
                    }
                    if (field.filter.value) {
                        parms[field.value] = field.filter.value;
                    }
                }
            }
        }
        this.router.navigate([this.config.navRoute.url, parms]);
    }

    private setupNewMode() {
        this.config.mode = 'new';
        this.titleService.setTitle(AppComponent.appName + ' - ' + $localize`New ${this.config.title}`);
    }

    private setupEditMode(itemId: uuid) {
        this.config.mode = 'edit';
        this.itemId = itemId;
        this.config.itemId = itemId;
        this.titleService.setTitle(AppComponent.appName + ' - ' + $localize`${this.config.title} ${this.itemId}`);
    }

    saveFieldVisibility(field: Field) {
        if (!field.filter.priorComputer && field.visible.computer) {
            field.filter.priorComputer = field.visible.computer;
        }
        if (!field.filter.priorPhone && field.visible.phone) {
            field.filter.priorPhone = field.visible.phone;
        }
    }

    private setQueryFilter(fld: Field, value: string | number) {
        fld.filter.active = true;
        fld.filter.value = value;

        this.saveFieldVisibility(fld);

        if (fld['picklist']) {
            if (fld.filter.value === 'All' || fld.filter.value === 'any') {
                return false;
            }
        }

        /*if ((fld.value === 'keyword' && !fld.filter.value)
            || (fld.value === 'name' && !fld.filter.value)
            || (fld.type === 'number' && !fld.filter.value)) {
            return false;
        }*/
        if (fld.filter.value === null) {
            return false;
        }

        this.requestParams = this.requestParams.set(fld.value, fld.filter.value);

        if (fld.type === 'picklist') {
            fld.visible.computer = false;
            fld.visible.phone = false;
        }

        return true;
    }

    private setViewFilterFields(view: TableView) {
        if (view.filterFields) {
            Object.getOwnPropertyNames(view.filterFields).forEach((fldName) => {
                const fld = this.config.fieldSet.getFieldByName(fldName);
                if (fld) {
                    this.setQueryFilter(fld, view.filterFields[fldName]);
                } else {
                    if (Array.isArray(view.filterFields)) {
                        console.error('Do not send empty array from PHP for filter fields, send nothing...')
                    } else {
                        console.warn('Filter has unknown filter fields', { config: this.config, fldName, view });
                    }

                }
            });
        }
    }

    private setCustomFilterFields(paramMap: ParamMap) {
        for (const field of this.config.fieldSet.fields) {
            if (paramMap.has(field.value)) {
                this.setQueryFilter(field, paramMap.get(field.value));
            }
        }
        if (paramMap.has('_sort')) {
            this.requestParams = this.requestParams.set('_sort', paramMap.get('_sort'));
        }
        if (paramMap.has('_sortDesc')) {
            this.requestParams = this.requestParams.set('_sortDesc', paramMap.get('_sortDesc'));
        }
    }

    private setUpFilterParams(paramMap: ParamMap) {
        this.currentView = null;
        if (paramMap.has('_view')) { // Use default parameters for the view...
            this.config.fieldSet.fields.map(f => f.filter.active = false); // A view turn off left over filters...

            //const view = this.config.navItem.views.find( o => o.id === paramMap.get('_view'));
            const user = this.currentUserSvc.currentUser;
            const views = this.config.navRoute.getViews(user);
            const view = views.find(o => o.id === paramMap.get('_view'));
            if (view) {
                this.currentView = view;
                this.setViewFilterFields(view);
                if (view.sort) {
                    this.requestParams = this.requestParams.set('_sort', view.sort);
                }
                if (this.config.charts) {
                    //const charts = view.charts ? view.charts : this.config.navItem.charts;
                    const charts = view.charts ? view.charts : this.config.navRoute.getCharts();
                    this.config.charts.setupCharts(charts, this.config.fieldSet);
                }
            }
        } else { // Check for field parameters (Mutually Exclusive?)
            this.setCustomFilterFields(paramMap);
        }
    }

    private setupDialogMode() {
        // For now dialogMode only supports edit view...
        const editId = this.dialogRef.componentInstance.dialogOptions?.id;
        console.log('setup dialog');
        if (editId) {
            this.setupEditMode(editId);
        } else {
            console.log('setup dialog NEW MODE');
            this.setupNewMode();
            /* List Mode does not work great in dialog, navigates on underlying view and is confusing

            const lp = this.dialogRef.componentInstance.dialogOptions.listParms;
            if (lp) {
                this.setupListMode(lp)
            } else {
                this.setupNewMode();
            }
            */
        }

    }

    private setupListMode(parms: Params) {

        const paramMap: ParamMap = convertToParamMap(parms);
        //const nav = this.navSvc.getItem(this.config.path);

        const charts = this.config.navRoute.getCharts();

        if (!this.config.charts && charts) {
            this.config.charts = new ChartAnalysis();
            this.config.charts.setupCharts(charts, this.config.fieldSet);
        }

        this.setUpFilterParams(paramMap);

        this.config.mode = 'list';

        let myTitle = $localize`${AppComponent.appName} - ${this.config.title} List View`;

        if (this.currentView) {
            myTitle = $localize`${AppComponent.appName} - ${this.currentView.name}`;
        }

        this.titleService.setTitle(myTitle);

        this.config.itemId = null;

        if (this.initCompleted) {
            this.refreshTable();  // There's a race condition, never sure if we get here before initCompleted...
        }
    }

    private getParm(ary: string[], idx: number) {
        if (ary[idx]) {
            return ary[idx];
        } else {
            return null;
        }
    }

    getForceTeamId() {
        if (this.dialogRef) {
            return this.dialogRef.componentInstance.dialogOptions?.forceTeamId;
        } else {
            return this.currentParams._forceTeam
        }
    }

    teamIsForced() {
        const forcedTeamId = this.getForceTeamId()
        const currTeamId = this.currentUserSvc.currentUser?.current_team_id;
        if (!currTeamId) {
            console.warn('Do not know if team is forced until I know the user...');
        }
        if (currTeamId && forcedTeamId && (currTeamId !== forcedTeamId)) {
            return true;
        }
        return false;
    }

    private setUp(parms: Params) {
        this.currentParams = parms;
        this.requestParams = new HttpParams();

        if (this.teamIsForced()) {
            this.forceTeamId = this.getForceTeamId();
            this.setAgentPrivs();
        } else if (this.currentUserSvc.gotUser) {
            this.forceTeamId = undefined;
            this.setLocalPrivs();
        } else {
            console.error('How did I get to hear without a user?');
        }

        this.newButtonService.setupButtonFromConfig(this.config, this.forceTeamId);

        const goDirect = parms.itemId;

        if (this.dialogRef) {
            this.setupDialogMode();
        } else if (this.config.forceMode && this.config.forceMode === 'new') {
            this.setupNewMode();
        } else if (goDirect) {
            if (goDirect === 'NEW') {
                this.setupNewMode();
            } else {
                this.setupEditMode(goDirect);
            }
        } else {
            this.setupListMode(parms);
        }
    }
}
