/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { DateHelper } from "src/app/shared/dateHelper";
import { ActionPageComponent } from "../../crm/action-page/action-page.component";
import { UnreadChart } from "./UnreadChart";

export class OverdueActionsChart extends UnreadChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/overdueActions';
    title = "My Overdue Actions";

    getSubTitle() {
        return super.getSubTitle($localize`Actions planned to complete on or before today`);
    }

    clickEvent() {

        const today = DateHelper.isoDate();
        const url = ActionPageComponent.navRoute.url;
        const params = { ownedById: this.dataUserId, completedAt: '', plannedDate: '<=' + today };

        this.router.navigate([url, params]);            

    }
}
