/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurrentUserService } from '../current-user.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { UntypedFormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { MyInjector } from 'src/app/app.module';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfServiceComponent } from 'src/app/shared/terms-of-service/terms-of-service.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgTemplateOutlet } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [MatProgressSpinnerModule, NgTemplateOutlet, MatExpansionModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatIconModule]
})
export class LoginComponent implements OnInit, AfterViewInit {

    static readonly navRoute = new NavRoute('login', LoginComponent, 'login');

    readonly sanctumUrl = '/api/sanctum/csrf-cookie';
    readonly loginUrl = '/api/login';
    username = '';//environment.defaultUser;
    password = ''; //environment.defaultPass;

    hide = true;
    showSpinner = false;
    showLoading = false;
    invalidUserOrPass = false;
    selectedPanel = 1;

    socialOpen = true;
    loginOpen = false;
    resetOpen = false;

    registering = false;

    usernameCtl = new UntypedFormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
    emailCtl = new UntypedFormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
    passwordCtl = new UntypedFormControl('', [Validators.minLength(4)]);

    private forwardTo = '/';

    constructor(private http: HttpClient,
        private currentUserService: CurrentUserService,
        private route: ActivatedRoute, private cds: ConfirmDialogService,
        private router: Router) {
        this.showLoading = currentUserService.gettingUser;
    }

    ngOnInit(): void {
        this.currentUserService.getCurrentUser().subscribe(() => {
            this.forwardOn();
            this.showLoading = this.currentUserService.gettingUser;
        }, error => {
            console.error('Error getting current user', error);
        });

        this.route.queryParams.subscribe(params => {
            if (params.returnUrl) {
                this.forwardTo = params.returnUrl;
            }
        });
    }

    ngAfterViewInit(): void {
        this.forwardOn();
    }

    forwardOn(): void {
        if (this.currentUserService.gotUser) {
            this.router.navigateByUrl(this.forwardTo).then(navigated => {
                console.log('Automatically forwarded', navigated);
            }).catch( () => {
                this.router.navigateByUrl('/');
            });
        }
    }

    oAuthLogin(provider: string): void {
        //const httpOptions = {
        //    headers: new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8')
        //};
        //this.http.get<Response>(this.sanctumUrl, httpOptions).subscribe(
        //    csrfResult => {
        //        console.log('Got CSRF', csrfResult);
        //        window.location.href = AbstractHttpService.ajaxPath + "oauth/redirect/" + provider;
        //    });
        CurrentUserService.returnMeTo(this.forwardTo);
        window.location.href = `/api/oauth/redirect/${provider}`;
    }

    validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    register(): void {
        console.log('Registering ', { username: this.username.toLowerCase() });
        if (!this.validateEmail(this.emailCtl.value)) {
            this.cds.alert('Enter an email address', 'Please enter a valid email address');
            return;
        }
        this.showSpinner = true;
        this.registering = true;
        const httpOptions = {
            headers: new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8')
        };

        this.http.post<Response>(AbstractHttpService.ajaxPath + 'reset/please',
            { email: this.emailCtl.value }, httpOptions
        ).subscribe(
            () => {
                this.cds.alert('Thanks', 'We have sent an email, please check your inbox and SPAM folders and click the link to setup your password');
                //window.location.href = 'http::/ouromc.ie';
                this.showSpinner = false;
                this.registering = false;
            },
            loginError => {
                console.log('loginFailed', loginError);
                this.invalidUserOrPass = true;
                this.showSpinner = false;
                this.registering = false;
            }
        );
    }

    login(): void {
        if (!this.usernameCtl.value || !this.passwordCtl.value) {
            return;
        }
        this.showSpinner = true;
        const httpOptions = {
            headers: new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8')
        };

        this.http.post<Response>(this.loginUrl, {
            email: this.usernameCtl.value.toLowerCase(),
            password: this.passwordCtl.value
        }, httpOptions).subscribe(
            () => {
                this.currentUserService.httpGetCurrentUser(this.forwardTo);
                this.showSpinner = false;
            },
            loginError => {
                console.log('loginFailed', loginError);
                this.invalidUserOrPass = true;
                this.showSpinner = false;
                if (loginError.status === 429) {
                    this.cds.alert('Too many attempts', 'Too many login attempts, please wait a moment before you try again');
                }
            }
        );
        //   },
        //   csrfError => { console.log('Failed to get CSRF Cookie', csrfError); this.showSpinner = false; }
        //);
    }

    openSocial() {
        this.socialOpen = true;
        this.loginOpen = false;
        this.resetOpen = false;
    }
    openLogin() {
        this.socialOpen = false;
        this.loginOpen = true;
        this.resetOpen = false;
    }
    openReset() {
        this.socialOpen = false;
        this.loginOpen = false;
        this.resetOpen = true;
    }

    termsOfService() {
        const dialog = MyInjector.instance.get(MatDialog)
        dialog.open(TermsOfServiceComponent, { autoFocus: false, data: { simpleClose: true } });
        return false; // Cancel the event!
    }
}
