/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, OnInit, ViewChild } from '@angular/core';
import { Privilege } from 'src/app/model/role';
import { DocumentService } from 'src/app/modules/crm/document.service';
import { Document } from 'src/app/model/document';
import { CurrentUserService } from '../../user/current-user.service';
import { User } from 'src/app/model/user';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { required } from 'src/app/shared/validators';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EditDialogComponent } from 'src/app/shared/dialogs/edit-dialog/edit-dialog.component';
import { first, map } from 'rxjs/operators';
import { AttachmentListComponent } from 'src/app/shared/form/file/attachment-list/attachment-list.component';
import { NewButtonService } from 'src/app/shared/new-button/new-button.service';
import { Attachment } from 'src/app/model/attachment';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { AttachmentControl } from 'src/app/shared/field/AttachmentField';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { AttachmentService } from 'src/app/shared/form/file/attachment.service';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { uuid } from 'src/app/model/abstract-object';
import { DocumentPageComponent } from '../document-page/document-page.component';
import { ByteCountPipe } from '../../../shared/pipes/byte-count.pipe';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { FileUploadComponent } from '../../../shared/form/file/file-upload/file-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { NgTemplateOutlet } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommsTemplateType } from 'src/app/model/CommsTemplate';
import { Area } from 'src/app/model/area';
import { AreaService } from '../../unit/area.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationService } from 'src/app/shared/navigation/navigation.service';

@Component({
    selector: 'app-document-detail-page',
    templateUrl: './document-detail-page.component.html',
    styleUrls: ['./document-detail-page.component.scss'],
    standalone: true,
    imports: [MatIconModule, RouterLink, NgTemplateOutlet, FormsModule, MatTooltipModule, FileUploadComponent,
        MatMenuModule, MatButtonModule,
        FromNowPipe, ByteCountPipe,
        CdkDrag, CdkDropList]
})
/* Removed Imports, seemingly unused: CdkDragPreview, CdkDragHandle, DragDropModule */
export class DocumentDetailPageComponent implements OnInit {
    @ViewChild(FileUploadComponent) fileUploadComponent;
    document: Document = new Document();
    title = "Our Documents";
    routerLink: unknown[] = ['/' + DocumentPageComponent.navRoute.url]
    routerQueryParms: Params = {};

    privileges = new Privilege();
    allowEdit = false;
    allowNew = false;
    printMode = false;

    attachmentField = AttachmentListComponent.make('', 'attachments',
        { service: this.docSvc, allowNew: false, smallAddBox: true });
    control = this.attachmentField.makeControl() as AttachmentControl;

    currentUser: User = new User();
    forceTeamId: uuid;

    addingChild = false;
    isPhone = false;

    childPageIds: uuid[] = [];
    sortedOn: string;
    sortedDir = -1;

    filter = '';

    areas: Area[] = [];
    areaField = FormPicklistComponent.make('Area', 'areaId', 'area',
        { refreshes: ['coreId'], items: this.areas, allowSelectNone: true }, { formColumn: 1 });
    coreField = FormPicklistComponent.make('Core', 'coreId', null, { items: [], allowSelectNone: true }, {
        formColumn: 1,
        refresh: (o: Area, control: AppPicklistControl) => {
            if (o) {
                control.field.setPicklistItems(o.cores);
                if (control.value && !o.cores.find(c => c.id === control.value)) {
                    control.setValue(null);
                }
            }
        }
    });

    constructor(private currentUserSvc: CurrentUserService,
        private docSvc: DocumentService,
        private router: Router, private dialog: MatDialog,
        private cds: ConfirmDialogService,
        private attachSvc: AttachmentService, areaSvc: AreaService,
        private newButtonSvc: NewButtonService,
        private isNarrowService: IsNarrowService,
        private navSvc: NavigationService,
        private activeRoute: ActivatedRoute) {

        isNarrowService.detectVeryNarrow().subscribe(result => { this.isPhone = result; });
        currentUserSvc.getCurrentUser().subscribe(user => {
            if (user) {
                this.currentUser = user;

                if (this.document) {
                    this.loadDocument(this.document);
                }
                console.log(user);
                this.privileges = User.privilege(user, 'Document');
            }
        });
        areaSvc.get<Area>(true).subscribe(areas => {
            this.areas = areas
            this.areaField.setPicklistItems(areas);
        })
    }

    addAttachment() {
        if (this.fileUploadComponent?.fileDropEl) {
            this.fileUploadComponent.fileDropEl.nativeElement.click();
        } else {
            console.warn('Could not find file uploader...')
        }
    }

    dropInPage($event, page: Document | uuid) {

        const attachment = $event.item.data as Attachment;
        console.warn('Dropping In Page', {attachment, page});
        const idx = this.document.attachments.indexOf(attachment);
        if (page['id']) {
            attachment.relatedId = page['id'];
        } else {
            attachment.relatedId = page as uuid;
        }

        this.attachSvc.put(attachment).subscribe ( () => {
            this.document.attachments.splice(idx, 1);
        })

    }

    sortOn(parm:'createdAt'|'size'|'name'|'ext') {
        if (parm === this.sortedOn) {
            this.sortedDir = this.sortedDir * -1;
        }
        const up = 1 * this.sortedDir;
        const dn = -1 * this.sortedDir;
        this.document.attachments.sort((a,b) => a[parm] > b[parm] ? up : dn);
        const childSort = this.getChildPageSort(parm);
        this.document.childPages.sort((a, b) => a[childSort] > b[childSort] ? up : dn);
        this.sortedOn = parm;
    }

    getChildPageSort(parm: 'createdAt' | 'size' | 'name' | 'ext') {
        switch (parm) {
            case 'name': return 'title';
            case 'size': return 'attachments_count';
            case 'ext': return 'parentId';
            default : return parm;
        }
    }

    setUpNewButton() {
        const title = $localize`Document Page`;
        const path = DocumentPageComponent.navRoute.newUrl;

        if (this.currentUserSvc.getForums('canCreate').length > 0) {
            if (this.forceTeamId) {
                this.newButtonSvc.setupButtonWithParms(title, path, { _forceTeam: this.forceTeamId });
            } else {
                this.newButtonSvc.setupButton(title, path, true);
            }
        }
    }

    ngOnInit(): void {
        this.activeRoute.params.subscribe(parms => {
            this.forceTeamId = parms._forceTeam;
            this.setUpNewButton();

            if (this.forceTeamId) {
                this.routerQueryParms = { _forceTeam: this.forceTeamId };
                this.routerLink = ['/' + DocumentPageComponent.navRoute.url, this.routerQueryParms];
            } else {
                this.routerQueryParms = {};
            }

            if (this.forceTeamId) {
                const agentRole = this.currentUser.currentRole.agentRoles.find(o => o.omcTeamId === this.forceTeamId);
                this.title = agentRole.omcName + ' Documents';
            } else {
                this.title = this.currentUser.currentTeam.name + ' Documents';
            }

            if (parms.itemId === 'NEW') {
                this.loadDocument(new Document());
                this.editDocument();
            } else if (parms.itemId) {
                this.docSvc.getOne(parms.itemId, null, this.forceTeamId).subscribe(doc => {
                    this.loadDocument(doc);
                })
            }
        });
    }
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }

    getExcerpt(doc: Document) {
        const div = document.createElement('div');
        div.innerHTML = doc.content;
        let excerpt = (div.textContent || div.innerText).substring(0, 100);
        if (excerpt.length === 100) {
            excerpt += ' ...';
        }
        return excerpt;
    }

    addChild(): void {
        const child = new Document();
        child.parentId = this.document.id;
        child.parent = this.document;
        child.forumId = this.document.forumId;
        child.forum = this.document.forum;

        this.loadDocument(child);
        this.addingChild = true;
        this.editDocument();
    }

    loadDocument(d: Document) {
        if (d === null) {
            d = new Document(); // avoid errors
        }
        this.document = d;
        this.control.setValue(d.attachments);
        if (d.id && this.privileges.put
            && User.hasForumPriv(this.currentUser, d.forumId, d.teamId, 'canEdit')) {
            this.allowEdit = true;
            this.control.field.fileOpts.allowNew = true;
            this.control.field.fileOpts.allowDelete = true;
        } else {
            this.allowEdit = false;
            this.control.field.fileOpts.allowNew = false;
            this.control.field.fileOpts.allowDelete = false;
        }
        if (User.hasForumPriv(this.currentUser, d.forumId, d.teamId, 'canCreate')) {
            this.allowNew = true;
        }
        this.childPageIds.length = 0;
        if (this.document.parentId) {
            this.childPageIds.push(this.document.parentId);
        }

        d.childPages.forEach( cp => this.childPageIds.push(cp.id));

        this.sortOn('name');
    }

    print() {
        const isWide = this.navSvc.navComponent.isWide;
        if (isWide) {
            this.navSvc.navComponent.isWide = false;
            this.navSvc.navComponent.setMenuStyle();
        }

        this.printMode = true;

        window.onafterprint = () => {
            this.printMode = false;
            if (isWide) {
                this.navSvc.navComponent.isWide = true;
                this.navSvc.navComponent.setMenuStyle();
            }
        }
            ;
        window.setTimeout(() => {
            window.print()
        }, 50);
        //window.print();

    }

    subTitle() {
        if (this.document) {
            const ret = this.document.forum.name;
            return ret;
        }
        return '';
    }

    downloadAttachmentFile($event, a: Attachment) {
        $event.preventDefault();
        Attachment.download(a, this.docSvc, this.forceTeamId);
    }

    routerDetailLink(itemId) {
        return ['/' + DocumentPageComponent.navRoute.getIdUrl(itemId), this.routerQueryParms];
    }

    newDocument(): Observable<Document> {
        if (this.document && this.document.id === null) {
            return of(this.document);
        } else {
            return of(new Document());
        }
    }
    editAttachment(attachment: Attachment) {
        Attachment.editAttachment(attachment, this.dialog, this.attachmentField, this.attachSvc);
    }

    deleteAttachment(attachment: Attachment) {
        Attachment.deleteAttachment(attachment, this.cds, this.attachmentField)
    }

    deletePage(doc: Document) {
        if (doc.attachments_count || doc.child_docs_count) {
            this.cds.alert('Cannot delete',
                `This document has ${doc.attachments_count} attachments and ${doc.child_docs_count} child pages
                you need to move that content before the page can be deleted`);
        } else {
            this.cds.open($localize`Delete Child Page`,
                $localize`Delete ${doc.title}?`,
                () => {
                    this.docSvc.delete(doc, this.forceTeamId).subscribe( result => {
                        if (result) {
                            const idx = this.document.childPages.indexOf(doc);
                            this.document.childPages.splice(idx, 1);
                        }
                    })
                }
            );
        }
    }

    editDocument() {

        const parentPL = FormPicklistComponent.make('Parent Document', 'parentId', 'parent',
            { items: [], optionDisplayValue: o => o.title, allowSelectNone: true }, {}
        );

        this.docSvc.getAllDocs(this.forceTeamId).subscribe(docs => {
            parentPL.setPicklistItems(docs.filter(d => d.id !== this.document.id));
        });

        const fields = [];

        const forumPicklist = FormPicklistComponent.make('Channel where this page is visible', 'forumId', 'forum',
            { items: [] }, { validators: [required] }
        )
        forumPicklist.setPicklistItems(this.currentUserSvc.getForums('canCreate'));

        fields.push(FieldMaker.id());
        fields.push(FieldMaker.rev());
        fields.push(FormTextComponent.make('Title for this page', 'title', { validators: [required] }));
        fields.push(forumPicklist);
        fields.push(this.areaField);
        fields.push(this.coreField);

        fields.push(parentPL)
        fields.push(FormRichTextComponent.make('Details', 'content',
            { formColumn: 2, richTextHeight: '354px' }).setCommsTemplate(CommsTemplateType.DOCUMENT));

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Documentation Page`,
                fieldSet: new FieldSet(
                    {
                        fields,
                        formLayout: [{ cells: [{ width: '33%' }, { width: '66%' }] },
                        { cells: [{ colspan: 2 }] }]
                    }
                ),
                mode: this.document.id ? 'edit' : 'new',
                objectFactory: this.newDocument.bind(this),
                newOptions: []
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: this.docSvc,
                    id: this.document.id,
                    forceTeamId: this.forceTeamId,
                    hideTabs: true,
                }
            }
        );

        dialogRef.afterClosed().pipe(first()).pipe<Document>(map(o => {
            if (this.addingChild) {
                this.addingChild = false;
                this.docSvc.getOne(this.activeRoute.snapshot.params.itemId, null, this.forceTeamId).subscribe(doc => this.loadDocument(doc));
            } else if (o) {
                this.loadDocument(o);
                if (this.activeRoute.snapshot.params.itemId !== o.id) { // New added
                    this.router.navigate(this.routerDetailLink(o.id));
                }
            } else if (!this.document.id) { // Not saved, ont on valid item, back to the list
                this.router.navigate(this.routerLink)
            }
            return o;
        })).subscribe();

    }
}
