<app-install-me></app-install-me>
@if(!isReady) {
    <mat-spinner style="margin:0 auto; margin-top: 20vh"></mat-spinner>

} @else if (currentUser) {
    <app-logged-in></app-logged-in>
} @else {
    <app-anonymous></app-anonymous>
}
<p class="build">
Build: {{buildVersion}}: {{buildDate}}
</p>

