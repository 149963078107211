/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import {ErrorHandler, inject, Injectable} from '@angular/core';
import { MessageService } from './message.service';
import { CurrentUserService } from '../modules/user/current-user.service';
import { AbstractHttpService } from './abstract-http.service';
import { MyInjector } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { OurAppTrackerService } from './our-app-tracker-service';

@Injectable({
    providedIn: 'root'
})
export class OurErrorHandlerService implements ErrorHandler {

    msgSvc = inject(MessageService);
    currentUserSvc = inject(CurrentUserService);
    ourAppTracker = inject(OurAppTrackerService); // Having this injected here, creates it and starts logging

    handleError(error) {
        this.logError(error);
        throw error;
    }

    logError(error) {
        let msg1;
        if (typeof error === 'string' || error instanceof String) {
            msg1 = error;
        } else {
            msg1 = error.message ? error.message : ( error.error.message ? error.error.message : error.toString())
        }

        const msg = $localize`Error: ${msg1} occured.
            Probably best if you reload your page before continuing. If the problem persists please contact OurOMC`;
        console.warn('Error happened, I caught it...', { error, msg });
        // Happens if you click on screen with chart and navigate away before it was instantiated
        if (!this.recognisedError(error)) {
            // Cannot see the failed http request due to subsequent errors
            //this.msgSvc.show(new MsgDef(msg, 'fail'));
            const errorMsg = `<p>Date: ${new Date()}</p>
                <p>Host: ${window.location.hostname}</p>
                <p>Path: ${window.location.pathname}</p>
                <p>User: ${this.currentUserSvc.currentUser?.name}</p>
                <p>Team: ${this.currentUserSvc.currentTeam?.name}</p>
                <p>Error Message : ${msg1}</p>
            `
            const stack = error.stack ? error.stack : (error.url ? "Calling " + error.url : '');

            if (!this.ourAppTracker.isReloading()) {
                localStorage.setItem('lastError', errorMsg);
                console.warn(stack);
                console.warn(this.ourAppTracker.printEvents());
                if (window.location.hostname !== 'localhost') {
                    this.sendMessage(msg1, errorMsg, stack);
                }
            }
        }
    }

    recognisedError(error) {
        if (error?.message === "Highcharts error #13: www.highcharts.com/errors/13/") {
            console.log('Highcharts generates errors if user navigates away from the page, before the chart finished initializing');
            return true;
        }
        if (error?.code === 4002) {
            console.log('Navigation error on first route ignored, typically user just typed URL incorrectly');
            if (this.ourAppTracker.logCount() <= 1) {
                return true;
            }
        }
    }

    sendMessage(errorShort : string, errorMsg: string, stack: string) {
        const url = AbstractHttpService.ajaxPath + 'informus';
        stack += this.ourAppTracker.printEvents();
        const data = {
            message: errorMsg + stack,
            subject: `Whoops - ${errorShort}`,
            sendTo: `support`
        }
        const http = MyInjector.instance.get(HttpClient);
        http.post(url, data).subscribe(() => {
            console.log('wow!');
        }, () => {
            console.log('surprise!')
        })
    }
}
