<div class="channels">
    <div style="margin-right: 5px;">Your Channels {{contentType}}</div>
    <mat-chip-set>
        @for (c of channels; track $index) {
            <div (click)="selectChannel(c)" [matTooltip]="c.from">
                <mat-chip [disabled]="this.selectedChannel && c.id !== this.selectedChannel.id">
                    <span style="cursor:pointer">{{c.name}}</span>
                </mat-chip>
            </div>
        }
    </mat-chip-set>
</div>
