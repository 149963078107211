
/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpParams } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { uuid } from 'src/app/model/abstract-object';
import { Action } from 'src/app/model/action';
import { OmcAgent } from 'src/app/model/OmcAgent';
import { Person } from 'src/app/model/person';
import { RequestTemplate } from 'src/app/model/RequestTemplate';
import { ServiceRequest } from 'src/app/model/serviceRequest';

import { Omc } from 'src/app/model/Omc';
import { Unit } from 'src/app/model/unit';
import { User } from 'src/app/model/user';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { DialogOptions, PickDialogComponent } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { Field } from 'src/app/shared/field/Field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { AttachmentListComponent } from 'src/app/shared/form/file/attachment-list/attachment-list.component';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormDurationComponent } from 'src/app/shared/form/form-duration/form-duration.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormError } from 'src/app/shared/form/form-error/form-error.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridControl } from 'src/app/shared/grid/grid-control';
import { GridField } from 'src/app/shared/grid/grid-field';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { maxValue, minValue, required } from 'src/app/shared/validators';
import { AgentDataService } from '../../agent/agent-data.service';
import { OmcService } from '../../agent/omc.service';
import { UnitService } from '../../unit/unit.service';
import { CurrentUserService } from '../../user/current-user.service';
import { OmcAgentService } from '../../user/omc-agent-service';
import { UserService } from '../../user/user.service';
import { RecurringJobService } from '../recurring-job/recurring-job.service';
import { RequestTemplatesService } from '../request-templates.service';
import { RequestService } from '../request.service';
import { ServiceRequestPageComponent } from '../service-request-page/service-request-page.component';
import { DateHelper } from 'src/app/shared/dateHelper';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { SupplierService } from '../../supply/supplier.service';
import { RequestPrioritiesService } from '../request-priorities.service';
import { RequestPriority } from 'src/app/model/RequestPriority';

@Component({
    selector: 'app-new-service-request',
    templateUrl: './new-service-request.component.html',
    styleUrls: ['./new-service-request.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class NewServiceRequestComponent extends AbstractPageComponent {

    users: User[] = [];

    approvedBadDate = null;

    //attachmentGrid = AttachmentGridComponent.make(this.dataSvc, { formRow: 2 },);
    attachmentGrid = AttachmentListComponent.make('Files', 'attachments',
        { service: this.dataSvc, allowNew: true, smallAddBox: true },
        { sendServer: true, visible: Field.formOnly, formRow: 2, name: 'attachments' },
    );

    jobIdField = FieldMaker.idHolder('jobId');
    templateIdField = ServiceRequest.getTemplateIdField();

    omcField : PicklistField = FormComboBoxComponent.make('Owner Management Company', 'omcId', 'omc',
        { items: [], refreshes: [this.omcRefresh.bind(this)]},
        {validators: [required], visible: Field.noShow}
    );

    parentField = ServiceRequest.getParentField();

    omcAgents: OmcAgent[] = [];

    agentField: PicklistField = FormPicklistComponent.make('Agent', 'agentTeamId', 'agentTeam',
        { items: this.omcAgents, refreshes: [this.agentRefresh.bind(this)] },
        { validators: [required], visible: Field.noShow }
    );
    teamField = FieldMaker.idHolder('teamId');

    titleField = ServiceRequest.getTitleField();

    personField = FormComboBoxComponent.make('Person', 'personId', 'person',
        {
            items: [],
            optionDisplayValue: o => Person.fullName(o as Person), allowSelectNone: true,
            refreshes: [this.refreshPerson.bind(this)]
        }, {
        formColumn: 2,
    });

    phoneField = FormPhoneComponent.make('Phone', 'person.phone', { disable: true, formColumn: 2, sendServer: false });
    emailField = FormEmailComponent.make('Email', 'person.email', { disable: true, formColumn: 2, sendServer: false });

    unitField = FormComboBoxComponent.make('Unit', 'unitId', 'unit',
        {
            items: [], allowSelectNone: true, optionDisplayValue: o => Unit.getFullName(o),
            refreshes: [(unit) => {this.unitSvc.refreshPeople(unit as Unit, this.personField, this.msgSvc)}] },
        {formColumn: 2}
    );

    ownedByField = ServiceRequest.getOwnedByField(this.users);

    supplierField = ServiceRequest.getSupplierField().override({visible: Field.noShow});

    isPhone = this.isNarrowSvc.isPhone();

    templateActionsField: GridField = new GridField({
        field: {
            label: 'To Do Checklist', name: 'actions', value: 'actions',
            formRow: 2, visible: Field.noShow, sendServer: true, tabIcon: 'checklist'
        },
        rowFactory: () => [
            FieldMaker.id({ visible: Field.noShow }),
            FormTextComponent.make('Title', 'title', { validators: [required], cellOpts: { heading: 'Name', width: '20%' } }),
            FormTextAreaComponent.make('Description', 'description', {}),
            FormDateComponent.make('Planned Date', 'plannedDate'),
            FormNumberComponent.make('Days Offset', 'offset',
                { width: 2}, {cellOpts:{ width: '2em'}, validators: [maxValue(30), minValue(-60)]}),
            FormDurationComponent.make('Default Time', 'timeSpent',{validators: [minValue(0), maxValue(960)]}),
            FormPicklistComponent.make('Owned By', 'ownedById', 'ownedBy', { items: this.users }, {cellOpts: { minWidth: '60px' } }),
            FormTextAreaComponent.make('Instructions', 'template.description', {disable: true, sendServer: false}),
            FieldMaker.rev(),
            FieldMaker.idHolder('templateId'),
            FieldMaker.idHolder('createdById'),
            FieldMaker.deleteGridRow(),
            FieldMaker.idHolder('omcId'),
            FieldMaker.idHolder('teamId')
        ],
        objFactory: () => {
            this.approvedBadDate = null;
            const newAction = new Action();
            newAction.createdById = this.currentUser.id;
            return of(newAction);
        },
        newOptionText: 'Add New ToDo Item',
    });

    plannedDateField = FormDateComponent.make('Planned Date', 'plannedDate', {
        valueChanges: this.setPlannedDate.bind(this),
        formColumn: 2,
        validators: [this.validatePlannedDate.bind(this)],
        asyncValidators : [this.asyncValidatePlannedDate.bind(this)]
    });

    config = new FormConfig({
        navRoute: ServiceRequestPageComponent.navRoute,
        title: $localize`Service Request`,
        help: $localize`Service Requests to get things done`,
        forceMode: 'new',
        fieldSet: new FieldSet({
            fields: this.getAgencyFields(),
            formLayout: [
                { cells: [{ width: '50%' }, { width: '16%' }, { width: '16%' }, { width: '16%' }] },
                 {cells: [{ colspan: 5, pageTab: 'yes'}]}
            ]
        }),
        service: this.dataSvc,
        objectFactory: this.newServiceRequest.bind(this),
        configReady: this.configReady,
        newOptions: [
            { name: $localize `New Service Request`, basePath: 'crm/requests/NEW', params: {}},
            { name: $localize `New Templated Request`, basePath: 'crm/requests/NEW', params: {templated: ''} }
        ],
    });

    prios: RequestPriority[] = [];

    constructor(public dataSvc: RequestService,
        omcSvc: OmcService, supplierSvc: SupplierService,
        private activeRoute: ActivatedRoute,
        private reqTemplateSvc: RequestTemplatesService,
        private agentDataSvc: AgentDataService,
        private unitSvc: UnitService,
        private personSvc: PersonService,
        private cds: ConfirmDialogService,
        private msgSvc: MessageService,
        private rjSvc: RecurringJobService,
        currentUserSvc: CurrentUserService,
        omcAgentSvc: OmcAgentService,
        teamUserSvc: UserService,
        private dialog: MatDialog,
        private isNarrowSvc: IsNarrowService,
        private prioSvc: RequestPrioritiesService,
        @Optional() public dialogRef: MatDialogRef<NewServiceRequestComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {
        super();

        isNarrowSvc.detectVeryNarrow().subscribe(result => { this.isPhone = result; });

        combineLatest([
            currentUserSvc.getCurrentUser(),
            teamUserSvc.getAgentUsers(),
            omcAgentSvc.get(),
            prioSvc.get(),
            omcSvc.get(),
            supplierSvc.get(true),
        ]).subscribe(
            ([currentUser, users, omcAgents, prios, omcs, suppliers]) => {
                this.currentUser = currentUser;

                for (const u of users as User[]) {
                    this.users.push(u);
                }

                for (const oa of omcAgents as OmcAgent[]) {
                    this.omcAgents.push(oa);
                }
                this.setupPrios(prios as RequestPriority[]);

                if (this.config.allowEdit) {
                    if (this.currentUser.inAgency) {
                        // If not in an agency, probably do not have permissions for these.
                        this.omcField.visible = Field.showAll;
                        this.agentField.visible = Field.noShow;
                        this.omcField.setPicklistItems(omcs);

                        if (!this.isPhone) {
                            this.supplierField.visible = Field.showAll;
                            this.supplierField.setPicklistItems(suppliers);
                            this.supplierField.allowEmpty();
                        }
                    } else {
                        this.omcField.visible = Field.noShow;
                        this.agentField.visible = Field.showAll;
                    }
                } else {
                    this.config.fieldSet.fields = this.getSimpleFields();
                }

                this.configReady.next(null);
            }
        );
    }

    setupPrios(prios: RequestPriority[]) {
        for (const p of prios as RequestPriority[]) {
            this.prios.push(p);
        }
    }

    refreshPerson(p: Person) {
        this.phoneField.control.setValue(p.phone, { emitEvent: false });
        this.emailField.control.setValue(p.email, { emitEvent: false });
    }

    isTemplated() {
        //remember templated=null gives picklist...
        if (this.dialogRef && this.dialogOptions['templateId']) {
            return true;
        }
        if (Object.prototype.hasOwnProperty.call(this.activeRoute.snapshot.queryParams, 'templated')) {
            return true;
        }
        return false;
    }
    parentParam() {
        if (this.dialogRef) {
            return this.dialogOptions['parentId'];
        }
        return this.activeRoute.snapshot.queryParams.parentId;
    }

    newServiceRequest(): Observable<ServiceRequest> {

        const parentId = this.parentParam();

        if (this.isTemplated()) {
            return this.findTemplate();
        } else if (parentId) {
            return this.findParent(parentId);
        } else {
            this.unitSvc.get(true).subscribe(units => this.unitField.setPicklistItems(units));
            this.personSvc.get(true).subscribe(people => this.personField.setPicklistItems(people));
            return of (this.createNewSR());
        }

    }

    setPlannedDate(value) {
        const gc = this.templateActionsField.control as GridControl;

        for (const row of gc.gridRows()) {
            //const newDate = moment(value, 'YYYY-MM-DD');
            const offset = row.controls.offset.value;
            //const offsetDate = newDate.add(offset, 'days');
            //const offsetCDate = offsetDate.format('YYYY-MM-DD');

            const offsetCDate = DateHelper.addDays(value, offset)

            if (!DateHelper.beforeToday(offsetCDate)) {
                row.controls.plannedDate.setValue(offsetCDate);
            } else {
                row.controls.plannedDate.setValue(DateHelper.today());
            }
        }
    }

    validatePlannedDate(control: AppFormControl): ValidationErrors {

        let validationError = null;

        if ((!control.value && control.value !== 0) || (typeof control.value === 'string' && control.value.trim().length === 0)) {
            if (this.isTemplated()) {
                validationError = FormError.reportError('validatePlannedDate',
                $localize`You must enter a valid planned date`);
            }
        } else if (DateHelper.beforeToday(control.value)) {
            validationError = FormError.reportError('validatePlannedDate',
                    $localize`If you enter planned Date, it must be after today`);
        }

        return validationError;
    }


    checkTooSoons(proposedDate) : {msg: string, count: number}{
        const gc = this.templateActionsField.control as GridControl;
        let count = 0;
        let msg = '';
        for (const row of gc.gridRows()) {
            const newDate = moment(proposedDate, 'YYYY-MM-DD');
            const offset = row.controls.offset.value;
            const offsetDate = newDate.add(offset, 'days');
            const offsetCDate = offsetDate.format('YYYY-MM-DD');
            const title = row.controls.title.value;

            if (DateHelper.beforeToday(offsetCDate)) {
                msg += $localize `${title} should be due ${Math.abs(offset)} days before (${offsetDate.format('DD/MM/YYYY')}), `
                count += 1;
            }
        }

        return { msg, count}

    }
    asyncValidatePlannedDate(control: AppFormControl) : Promise<ValidationErrors> | Observable<null> {
        if (!control.dirty || (this.approvedBadDate && this.approvedBadDate === control.value)) {

            return of(null);
            /*
// Need timeout to avoid ExpressionChangedAfterItHasBeenCheckedError
            return new Promise<ValidationErrors|null>( (resolve) => {
                setTimeout( () => {
                    console.log('Resolving');
                    resolve(null);
                }, 5000)
            });*/
        }
        const tooSoons = this.checkTooSoons(control.value);
        if (tooSoons.count === 0) {
            return of(null);
        }

        return new Promise<ValidationErrors>( (resolve) => {
            this.cds.openChoice( {
                title: $localize `${tooSoons.count} Items Required Earlier - Sure you wish to proceed?`,
                msg: tooSoons.msg + '. All of these will need to be requested today',
                options : [
                    {name: 'OK', action: () => {
                        this.approvedBadDate = control.value;
                        resolve(null);
                    }},
                    { name: 'Cancel', action: () => {
                        resolve( {plannedToSoon: $localize `Planned date provides insufficient time to complete`} as ValidationErrors);
                    }}
                ]
            }, false);
        });
    }

    getParam(param:string) {
        if (this.dialogRef) {
            return this.dialogOptions[param];
        } else {
            const snap = this.activeRoute.snapshot;
            if (snap.params[param]) {
                snap.params[param];
            } else if (snap.queryParams[param]) {
                return snap.queryParams[param];
            } else {
                return null;
            }
        }
    }


    createNewSR() {
        // Shoddy, but code is duplicated in ScheduleJobsStepperComponent.makeRequest()
        const sr = new ServiceRequest();
        sr.refNr = null;

        sr.srStatus = ServiceRequest.srOpen.id;

        sr.teamId = this.currentUser.current_team_id;
        sr.reportedBy = this.currentUser;
        sr.reportedById = this.currentUser.id;

        const prio = this.prios.find( rp => rp.defaultPrio);
        if (prio && this.currentUser.inAgency) { // For now cannot set default Prio or any prio if in the OMC
            sr.priorityId = prio.id;
        }


        const omcId = this.getParam('omcId');
        if (omcId) {
            sr.omcId = omcId;
            sr.unitId = this.getParam('unitId');
            sr.personId = this.getParam('personId');
            if (this.currentUser.inOmc && this.currentUser.inOmc.id === omcId) {
                sr.omc = this.currentUser.inOmc;
            } else {
                sr.omc = this.omcField.picklist.items.find(o => o.id === sr.omcId) as Omc;
            }
            setTimeout(() => this.omcRefresh(sr.omc, sr.unitId, sr.personId), 50);
            this.omcField.disable = true;
        }

        const supplierId = this.getParam('supplierId');
        if (supplierId) {
            sr.supplierId = supplierId;
        }
        sr.jobId = this.getParam('jobId');
        if  (!this.currentUser.inAgency) {
            if (this.omcAgents.length === 1) {
                sr.agentTeamId = this.omcAgents[0].agentTeamId;
                setTimeout(() => {
                    this.agentRefresh(this.omcAgents[0], sr.unitId, sr.personId)
                    this.agentField.disableControl();
                }, 50);
            }
        }

        return sr;
    }

    getSimpleFields() {
        return [
            FieldMaker.id(),
            ServiceRequest.getRefField(),
            FieldMaker.rev(),
            ServiceRequest.getTitleField(),
            ServiceRequest.getDescriptionField(),
            this.attachmentGrid,
        ];
    }

    agentRefresh(oa: OmcAgent, unitId: uuid = null, personId: uuid = null) {
        this.omcField.control.setValue(oa.omc.id, {emitEvent: false});
        this.omcRefresh(oa.omc, unitId, personId);
    }

    omcRefresh(omc: Omc, unitId: uuid = null, personId: uuid = null) {
        this.parentField.picklist.items.length = 0;
        this.parentField.control.setValue(null, {emitEvent: false});

        this.personField.control.setValue(personId, {emitEvent: false});
        if (!personId) {
            this.personField.filterControl.setValue(null);
        }
        this.personField.picklist.items.length = 0;

        this.unitField.control.setValue(unitId, {emitEvent: false});
        if (!unitId) {
            this.unitField.filterControl.setValue(null);
        }
        this.unitField.picklist.items.length = 0;

        if (omc.omcTeamId) {
            if (this.templateIdField.control.value) {
                const rjParms = new HttpParams().set('templateId', this.templateIdField.control.value);
                this.rjSvc.get(false, rjParms, omc.omcTeamId).subscribe( rjResult => {
                    if (rjResult) {
                        this.jobIdField.control.setValue(rjResult[0].id, {emitEvent: false});
                    }
                });
            }
            this.agentDataSvc.getOmcUnits(omc).pipe(first()).subscribe( units => {
                this.unitField.setPicklistItems(units);
            });
            this.agentDataSvc.getOmcPeople(omc).pipe(first()).subscribe ( people => {
                this.personField.setPicklistItems(people);
            })
            this.agentField.control.setValue(omc.teamId, {emitEvent: false});
            this.teamField.control.setValue(omc.omcTeamId, {emitEvent: false});
            if (omc.adminId) {
                this.ownedByField.control.setValue(omc.adminId, {emitEvent: false});
            } else if (omc.managerId) {
                this.ownedByField.control.setValue(omc.managerId, {emitEvent: false});
            } else {
                this.ownedByField.control.setValue(this.currentUser.id, {emitEvent: false});
            }

            this.ownedByField.setComboDisplayValue({id: omc.managerId});
            this.updateActions(omc);
        } else {
            console.warn('OMC Has not been setup as a team', omc);
            const msg = $localize `OMC  ${omc.name} has not been configured as a team, cannot create service requests yet`;
            const m  = new MsgDef(msg, 'warn')
            this.msgSvc.show(m);
            this.teamField.control.setValue(this.currentUser.current_team_id, { emitEvent: false });
        }

        this.dataSvc.refreshParents(omc.id, this.parentField);
    }

    updateActions(omc: Omc) {
        const rows = this.templateActionsField.control.gridRows();
        for (const row of rows) {
            row.controls.omcId.setValue(omc.id, { emitEvent: false });
            if (omc.omcTeamId) {
                row.controls.teamId.setValue(omc.omcTeamId, {emitEvent: false});
            } else {
                row.controls.teamId.setValue(omc.teamId, {emitEvent: false });
            }
        }
    }

    getAgencyFields() {
        const refField = ServiceRequest.getRefField();
        const reportedByField = ServiceRequest.getReportedByField(this.users);
        const ooField = ServiceRequest.getOOHoursField();
        const statusField = ServiceRequest.getStatusField().override({ disable: true });
        const subStatusField = ServiceRequest.getSubStatusField().override({ disable: true });
        const billableField = ServiceRequest.getBillableField();
        const createdField = ServiceRequest.getCreatedField();
        const lastUpdatedField = ServiceRequest.getLastUpdatedField();

        if (this.isPhone) {
            refField.visible = Field.noShow;
            this.parentField.visible = Field.noShow;
            this.unitField.visible = Field.noShow;
            this.personField.visible = Field.noShow;
            this.phoneField.visible = Field.noShow;
            this.emailField.visible = Field.noShow;
            this.supplierField.visible = Field.noShow;
            reportedByField.visible = Field.noShow;
            ooField.visible = Field.noShow;
            statusField.visible = Field.noShow;
            subStatusField.visible = Field.noShow;
            billableField.visible = Field.noShow;
            createdField.visible = Field.noShow;
            lastUpdatedField.visible = Field.noShow;
            this.ownedByField.visible = Field.noShow;
        }

        const fields = [
            FieldMaker.id(),
            FieldMaker.rev(),
            refField,
            this.templateIdField,
            this.jobIdField,
            this.omcField,
            this.agentField,
            this.teamField,
            this.titleField,
            ServiceRequest.getDescriptionField(),
            this.parentField,
            this.plannedDateField,
            this.unitField,
            this.personField,
            this.phoneField,
            this.emailField,
            this.supplierField,
            reportedByField,
            ooField,

            ServiceRequest.getCategoryField(),
            ServiceRequest.getPriorityField(),
            this.ownedByField,
            statusField,
            subStatusField,
            billableField,
            createdField,
            lastUpdatedField,

            this.templateActionsField,
            //this.attachmentGrid,
        ];

        return fields;
    }

    findParent(parentId: uuid) {
        return this.dataSvc.getOne(parentId, null).pipe<ServiceRequest>(
            map( this.parentMapper.bind(this))
        )
    }

    findTemplate() : Observable<ServiceRequest> {
        const templateId = this.getParam('templateId');
        if (templateId) {
            return this.reqTemplateSvc.getOne(templateId, new RequestTemplate()).pipe<ServiceRequest>(
                map(this.templateMapper.bind(this))
            );
        } else {
            return this.chooseTemplate();
        }
    }

    chooseTemplate() {

        const config = new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FieldMaker.id(),
                    FieldMaker.rev(),
                    FormTextComponent.make('Name', 'name'),
                    FormTextComponent.make('Description', 'description'),
                ],
                formLayout: LAYOUT_OPTIONS.singleCol,
            }),
            title: $localize`Request Template`,
            mode: 'list',
        });

        const dialogRef = this.dialog.open(PickDialogComponent, { data: { config, service: this.reqTemplateSvc } });

        return dialogRef.afterClosed().pipe(first()).pipe<ServiceRequest>(
            map(this.templateMapper.bind(this))
        );
    }

    parentMapper(parent: ServiceRequest): ServiceRequest {
        const sr = this.createNewSR();

        if (parent) {
            sr.parentId = parent.id;
            sr.parent = parent;
            sr.omcId = parent.omcId;
            sr.omc = parent.omc;
            sr.teamId = parent.teamId;
            sr.agentTeamId = parent.agentTeamId;
            this.dataSvc.refreshParents(sr.omcId, this.parentField);
        }

        return sr;
    }

    templateMapper(template: RequestTemplate ): ServiceRequest {
        const sr = this.createNewSR();

        if (template) {
            this.config.tabFields = [
                this.templateActionsField
            ];
            ServiceRequest.templateMapper(template, sr, this.currentUser);
        }

        return sr;
    }
}
export function validatePlannedDate(control: AppFormControl): ValidationErrors {

    let validationError = null;

    if ((!control.value && control.value !== 0) || (typeof control.value === 'string' && control.value.trim().length === 0)) {
        if (this.isTemplated()) {
            validationError = FormError.reportError('validatePlannedDate',
                $localize`You must enter a valid planned date`);
        }
    } else if (this.beforeToday(control.value)) {
        validationError = FormError.reportError('validatePlannedDate',
            $localize`If you enter planned Date, it must be after today`);
    }

    return validationError;
}
