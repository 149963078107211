/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { MatDialog } from "@angular/material/dialog";
import { of } from "rxjs";
import { MyInjector } from "src/app/app.module";
import { Frequency } from "src/app/model/frequency";
import { RecurringJob } from "src/app/model/recurring-job";
import { RequestTemplate } from "src/app/model/RequestTemplate";
import { EditDialogComponent } from "src/app/shared/dialogs/edit-dialog/edit-dialog.component";
import { Field } from "src/app/shared/field/Field";
import { FieldMaker } from "src/app/shared/field/FieldMaker";
import { FieldSet, LAYOUT_OPTIONS } from "src/app/shared/form/field-set/field-set.component";
import { FormDateComponent } from "src/app/shared/form/form-date/form-date.component";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { FormConfig } from "src/app/shared/form/FormConfig";
import { RecurringJobService } from "./recurring-job.service";
import { SupplierService } from "../../supply/supplier.service";

export class RecurringJobEditor {
    dialog = MyInjector.instance.get(MatDialog);
    jobService = MyInjector.instance.get(RecurringJobService);
    supplierSvc = MyInjector.instance.get(SupplierService);

    fields = [
        FieldMaker.id(),
        FieldMaker.rev(),
        FormTextComponent.make('Job', 'omc.name', { disable: true, sendServer: false, calculateValue: (o: RecurringJob) => {
            return o.omc?.name + '/'+o.template?.name
        } }),
        FieldMaker.idHolder('omcId'),
        FieldMaker.idHolder('templateId'),
        FormPicklistComponent.make('Frequency', 'frequencyId', 'frequency',
            { items: Frequency.ALL_OPTIONS, optionDisplayValue: o => o.name },
        ),
        FormDateComponent.make('Next request needs to be created', 'nextOccurence'),
        FormPicklistComponent.make('Supplier', 'supplierId', 'supplier', {service: this.supplierSvc}),
        FieldMaker.notes(),
    ];

    fieldSet = new FieldSet({
        fields: this.fields,
        formLayout: LAYOUT_OPTIONS.simpleDialog
    });

    constructor(private rj: RecurringJob) {}

    getConfig() { return new FormConfig({
        fieldSet: this.fieldSet,
        mode: this.rj.id ? 'edit' : 'new',
        objectFactory: () => of(this.makeJob(this.rj.template)),
        title: $localize`Recurring Job`
    })}

    makeJob(rt: RequestTemplate) {
        const newJob = new RecurringJob();
        //newJob.omcId = this.omc.id;
        //newJob.omc = this.parentObject as Omc;
        newJob.templateId = rt.id;
        newJob.template = rt;
        //newJob.teamId = this.parentObject.teamId;
        newJob.template = rt;
        newJob.frequencyId = rt.frequencyId;
        newJob.frequency = rt.frequency
        return newJob;
    }

    editJob() {
        this.dialog.open(EditDialogComponent,
            {
                data: {
                    //teamId: rj.teamId,enable
                    config: this.getConfig(),
                    id: this.rj.id,
                    service: this.jobService,
                    hideTabs: true,
                    width: 440,
                }
            }
        ).afterClosed().subscribe((updated) => {
            if (updated) {
                //afc.getRow().refreshFrom(updated);
                Field.shallowCopy(updated, this.rj);
            }
        });
    }
}
